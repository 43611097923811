import CustomTable from "../../../CustomTable";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const OnlineMBA = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Online MBA</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          In the current scenario, Online MBA is a practically the convenient
          mode of skill enhancement for working professional without sacrificing
          their jobs. Online MBA has gained popularity among professionals, as
          well as fresh graduates due to the flexible nature of the course.
          &nbsp;Some of the greatest advantage of Online MBA is that it is
          flexible, convenient, economical and enriching. Online MBA course is
          usually a 12-month programme, which requires participants' engagement
          on weekends only. Let's take a look at Online MBA in detail.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <CustomTable />

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Online MBA Course Details</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Particulars
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Online MBA
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Course Level
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Post Graduation Degree
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Duration
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years to 5 years
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Average Fees
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 1 Lac – 3 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 3 Lacs to 7 Lacs
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Employment Roles
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  HR Manager, Marketing Manager, Business Development Manager,
                  Operations Manager, Business Analyst (IT), Senior Business
                  Analyst, Financial Analyst, SAP Consultant, Marketing
                  Executive, etc.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "157.3pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={210}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Top Online MBA Specialisations
                </p>
              </td>
              <td
                style={{
                  width: "321.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={429}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Sales, Marketing, Operations, Finance, Human Resources,
                  Digital Marketing, Business Analytics
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Online MBA: Key Highlights</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b style={{ fontSize: '11pt' }}><u>Ideal for Working Professionals:</u></b> Online MBA course is ideal for
          working professionals. By pursuing the Online MBA course,
          professionals can give their career an upgrade and enhance their
          chances of swift promotion, better pay package and more favourable
          career prospects.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b style={{ fontSize: '11pt' }}><u>Supplement Distance MBA:</u></b> Online MBA is an advanced version of
          distance learning as lectures are imparted through video conferencing
          and the notes or recordings of lectures can be carried in pen drives.
          Another advantage of Online MBA is that it is flexible.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Some of the job profiles which you can get after completing Online MBA
          are Corporate Associate, Portfolio Manager, Finance Analyst, Credit
          Risk Manager, Executive and Senior Executive, etc.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Online MBA Specialisations</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Similar to the full-time MBA program, there are multiple
          specialization choices for a student to choose form. A student must
          choose an Online MBA specialisation as per their area of work and
          experience in a particular field to gain maximum benefits from the
          course. Some of the specialization choices are:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Finance Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Marketing Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Healthcare Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Hospitality Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Business Analytics
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Human Resources
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Information Technology
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            International Business
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Cybersecurity, Information Security Analysis
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Entrepreneurship
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Operations Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Public Administration
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Risk Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Advertising and PR Management
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <a href="#">
            Logistics and Supply Chain Management
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "red" }}>
            <Link to="/mba-more-specialisations">
              Read More about MBA Specialisations{" "}
            </Link>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top Online MBA Colleges</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Some of the top colleges offering Online MBA program, its fees and
          specialisations are:
        </p>
        <table
          className="MsoTableGrid"
          style={{
            borderCollapse: "collapse",
            border: "none",
          }}
          width={634}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "135.9pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={181}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  University / College Name
                </p>
              </td>
              <td
                style={{
                  width: "249.95pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={333}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program
                </p>
              </td>
              <td
                style={{
                  width: "1.25in",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={120}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Fees (in Rupees)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "135.9pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={181}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  NMIMS Global Access School for Continuing Education, Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "249.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={333}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Online MBA
                </p>
              </td>
              <td
                style={{
                  width: "1.25in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={120}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1.40 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "135.9pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={181}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Symbiosis Centre for Distance Learning, Noida
                </p>
              </td>
              <td
                style={{
                  width: "249.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={333}
                valign="top"
              >
                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGDBA
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGDHRM
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in Supply Chain Management
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in Business &amp; Corporate Law
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in Information Technology
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in International Business
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in Export &amp; Import Management
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in Customer Relationship Management
                </p>
                <p
                  className="MsoListParagraphCxSpLast"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  PGD in Retail Management
                </p>
              </td>
              <td
                style={{
                  width: "1.25in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={120}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  20,000 – 50,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "135.9pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={181}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Amity University Online, Noida
                </p>
              </td>
              <td
                style={{
                  width: "249.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={333}
                valign="top"
              >
                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online MBA
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online PGD in Finance &amp; Accounting
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online PGD in Public Accounting
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online PGD in HRM
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online PGD in Travel &amp; Tourism Management
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online PGD in Logistics &amp; Supply Chain Management
                </p>
                <p
                  className="MsoListParagraphCxSpLast"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online PGD in Advertising &amp; Brand Management
                </p>
              </td>
              <td
                style={{
                  width: "1.25in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={120}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1.20 Lac to 1.80 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "135.9pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={181}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jain Deemed to be University
                </p>
              </td>
              <td
                style={{
                  width: "249.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={333}
                valign="top"
              >
                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online MBA International Finance
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online MBA in Investment Banking
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online MBA in Strategic Finance
                </p>
                <p
                  className="MsoListParagraphCxSpLast"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >

                  Online MBA in Systems &amp; Operations
                </p>
              </td>
              <td
                style={{
                  width: "1.25in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={120}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  80,000 – 2.10 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "135.9pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={181}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Online Manipal: Manipal University Jaipur
                </p>
              </td>
              <td
                style={{
                  width: "249.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={333}
                valign="top"
              >
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Online MBA
                </p>
              </td>
              <td
                style={{
                  width: "1.25in",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={120}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1.50 Lac
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>FAQs of Online MBA</b>
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the career opportunities after online MBA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: There are good career opportunities after an online MBA, but it
          depends on the MBA College and course which you opt. An online MBA
          degree from a reputed college can help you grow in your profession.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the difference between distance MBA and online MBA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A: The major difference between distance MBA and online MBA is the
          course delivery method. In Distance MBA self-study material is usually
          sent to candidates by post and its mostly self study with some fixed
          classes at study centers. In Online MBA study material is provided
          online and classes are conducted through Learning Management System
          (LMS).
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: How to choose an online MBA course?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: While choosing an online MBA course one must keep in mind, the
          course curriculum, college accreditation, learning system and
          pedagogy, course fees and flexibility of classes and project
          submission.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the fee structure of online MBA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A: Fees range of Online MBA is around Rs. 1 Lac to Rs 3 Lac. So it is
          not much expensive.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the entrance exams for admission to Online MBA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A: Admission is based on both entrance exam and direct on academics
          merit, so it depends upon the college which method they choose for.
          Some colleges take into consideration scores of MBA entrance exams
          such CAT, XAT, CMAT, MAT, NMAT etc
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the job roles offered to online MBA graduates?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The job roles offered to online MBA graduates include HR
          Managers, Project Managers, IT Manager, Business Development Manager,
          ERP Consultants and Operations Research Analyst, etc.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the specialisations available in online MBA courses?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Some of the specialisations available in online MBA are Sales
          &amp; Marketing, Business Analytics, Digital Marketing, Data Science,
          Supply Chain, IT &amp; Systems, Human Resources, Finance, Retail, etc.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Is it necessary to have prior work experience to pursue an online
          MBA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A: No, it is not mandatory to have prior work experience but it will
          be an advantage for the participant if he/she has work experience.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the disadvantages of online MBA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A: As you won’t be attending physical classrooms, you won’t be getting
          exposure to network of professionals from different background.
          Moreover, online MBA degree is not considered at par with a full-time
          MBA degree in India, hence career growth is limited.
        </p>
      </div>
    </div>
  );
};

export default OnlineMBA;
