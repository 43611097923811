import { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../CustomTable";
const OilGas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Oil &amp; Gas Management</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Fuel is a global product and fuel based industry is constantly on rise
          as demands for naturally occurring fuels and their extraction is
          global and also needed on a regular basis. &nbsp;MBA in Oil &amp; Gas
          Management is a niche and budding field that offers vast expanse of
          experience and work opportunities. Oil and gas management requires an
          individual to study the basic premise of formation, extraction, usage
          and conservation of this utmost valuable source of energy and also it
          can be utilized to its maximum potential.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA in Oil &amp; Gas Management is a very eccentric career discipline,
          in a hoard of plenty of MBA specialisations being pursued; this
          discipline is a blend of fresh curriculum and an industry which is
          very stable and secure. Oil &amp; Gas management course help a
          candidate with the technicalities and management skills required to
          run the oil and gas Industries. These courses are relevant for
          engineers, managers, supervisors, employee representatives and newly
          appointed health and safety advisors all over the world who are in
          charge of safeguarding general health and safety within the oil and
          gas industry as part of their daily work.
        </p>

        <b className="table-details">Table of content</b>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#1">
            MBA In Oil &amp; Gas Management  – Program Highlights</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#2">
            MBA In Oil &amp; Gas Management   – Syllabus</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#3">
            MBA In Oil &amp; Gas Management  : Skills Required</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#4">
            MBA In Oil &amp; Gas Management : Job Profiles Offered</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >

          Top Colleges in India for MBA In Oil &amp; Gas Management
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#6">
            MBA In Oil &amp; Gas Management  - Top Recruiters
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#7">
            FAQs – MBA In Oil &amp; Gas Management
          </a>
        </p>

        <p id="1"
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Oil &amp; Gas Management – Program Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Parameters
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program Type
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation Degree with minimum 50 per cent (45 per cent for
                  reserved category) in B Tech, BE, B Sc (PCM)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Test followed by GD – WAT – PI
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT, XAT, CMAT, MAT,&nbsp; etc
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program Option
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full Time
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Fundamentals Of Oil &amp; Gas Business
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Fundamentals Of Petroleum Exploration
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Petroleum Industry Accounting
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Fundamental of Refining
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Petroleum Financial Management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Fees
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 1 Lac to Rs. 15 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 5 Lac to 7 Lac per annum
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Job Profiles
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Oil and Gas Analyst
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Energy Analyst
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Managers (Maintenance and control)
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Oilfield Sales Management Executives
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />

        <p
          className="MsoNormal" id="2"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>


        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Oil &amp; Gas Management – Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from the core subjects, candidates are taught the following
          subjects in MBA in Oil &amp; Gas Management specialisation programme:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Fundamentals Of Oil &amp; Gas Business
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Fundamentals Of Petroleum Exploration
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Petroleum Industry Accounting
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Understanding Of Natural Gas Business
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Fundamental of Refining
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Petroleum Financial Management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Data Analytics &amp; Applications in Oil &amp; Gas
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Petro Economics
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Financing Petroleum Sector Projects
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Supply Chain &amp; Logistics For Petroleum Industry
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  E- Enterprise Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Health, Safety &amp; Environment for Petroleum Sector
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="MsoNormal" id="3"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Oil &amp; Gas Management: Skills Required</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Functional-specific skills that one need to have to succeed in MBA in
            Oil &amp; Gas Management are:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Management skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Technical skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Research skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Problem-solving skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Commitment
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Leadership skills
          </p>
        </div>
        <p
          className="MsoNormal" id="4"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>

        <div className="required-skills">
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Oil &amp; Gas Management: Job Profiles Offered</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            MBA in Oil &amp; Gas Management opens up opportunities in petroleum
            industry along with associated industries such as petro-chemical,
            shipping, engineering industry as well as various consultancy
            organizations. &nbsp;Job profiles offered to candidates are:
          </p>
          <p
            className="cont"
            style={{ textIndent: "-0.25in" }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Field Material Coordinator
          </p>
          <p
            className="cont"
            style={{ textIndent: "-0.25in" }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Oil Field Sales Manager
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Oil and Gas Analyst
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Energy Analyst
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Managers (Maintenance and control)
          </p>
          <p
            className="cont"
            style={{ textIndent: "-0.25in" }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Oilfield Sales Management Executives
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top Colleges in India for MBA in Oil &amp; Gas Management</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Only few colleges in India offer Oil &amp; Gas program. Some of the
          top colleges are UPES, Dehradun, Pandit Deendayal Petroleum University
          (PDPU), Gandhinagar-Gujarat, Rajiv Gandhi Institute of Petroleum
          Technology, Amethi, Great Lakes Institute of Management, Chennai,
          Management Development Institute, Gurgaon. To find out about the top
          colleges of India offering MBA / PGDM in Oil &amp; Gas Management,
          <span style={{ color: "red" }}>            <Link to="/college-list?m=Top Private MBA Colleges">
            click here
          </Link></span>.
        </p>
        <p
          className="MsoNormal" id="6"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Oil &amp; Gas Management - Top Recruiters </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            Hiring is done by petroleum industry along with associated industries
            such as petro-chemical, shipping, engineering industry as well as
            various consultancy organizations.&nbsp; Some of the top recruiters
            are:
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            &nbsp;
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>ONGC</p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>HPCL</p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Reliance Petroleum</p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>GAIL</p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Shell</p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Essar Oil</p>

        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />
        <p
          className="MsoNormal" id="7"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>FAQs – MBA in Oil &amp; Gas Management</b>
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the scope of MBA in Oil &amp; Gas Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: MBA in Oil &amp; Gas Management is a very eccentric career
          discipline, in a hoard of plenty of MBA specialisations being pursued;
          this discipline is a blend of fresh curriculum and an industry which
          is very stable and secure. Candidates get an opportunity to work with
          some of the biggest MNC’s of the world with a very settled and
          lucrative career.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the eligibility for MBA in Oil &amp; Gas?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. For MBA / PGDM in Oil &amp; Gas Management, a student needs to a
          have valid degree with minimum 50 per cent marks in B. Tech, B. E. or
          B. Sc (Physics, Chemistry &amp; Maths) . For reserved category
          students the marks is 45 per cent.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top entrance exams for MBA in Oil &amp; Gas
          Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The top entrance exams for MBA in Oil &amp; Gas Management are
          CAT, XAT, MAT, CMAT, and GMAT.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Which are the best colleges to pursue MBA in Oil &amp; Gas
          Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Top colleges in India offering MBA in Oil &amp; Gas Management
          are UPES, Dehradun, Pandit Deendayal Petroleum University (PDPU),
          Gandhinagar-Gujarat, Rajiv Gandhi Institute of Petroleum Technology,
          Amethi, Great Lakes Institute of Management, Chennai, Management
          Development Institute, Gurgaon. To find out about the top colleges of
          India offering MBA / PGDM in Oil &amp; Gas Management,{" "}
          <span style={{ color: "red" }}>            <Link to="/college-list?m=Top Private MBA Colleges">
            click here
          </Link></span>.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the fee structure of an MBA in Oil &amp; Gas Management
          program?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Fees is totally dependent upon the college and also upon the
          college being a government college or a private college. Fees range
          for MBA in Oil &amp; Gas Management program is Rs. 1 Lac to Rs. 15
          Lac.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are the job profiles offered to candidates holding degrees in
          Oil &amp; Gas?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. There is no dearth of job roles for qualified candidates. Some of
          the popular job roles offered to candidates holding degrees in Oil
          &amp; Gas are Field Material Coordinator, Oil Field Sales Manager, Oil
          and Gas Analyst, Energy Analyst, Managers (Maintenance and control),
          Oilfield Sales Management Executives etc.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are some top recruiters after oil and gas courses?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans. Hiring is done by petroleum industry along with associated
          industries such as petro-chemical, shipping, engineering industry as
          well as various consultancy organizations.&nbsp; Some of the top
          recruiters are ONGC, HPCL, Reliance Petroleum, GAIL, Shell, Essar Oil
          etc.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the average salary offered after completing MBA in Oil
          &amp; Gas Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. A fresher after completing MBA in Oil and Gas Management earns on
          an average Rs 5.5 Lac per annum. This can increase multifold with work
          experience and industry knowledge.
        </p>
      </div>
    </div>
  );
};

export default OilGas;
