import CustomTable from "../../../CustomTable";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const BankingInsurance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Banking &amp; Insurance Management</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA in Banking & insurance Management is banking based course which covers the concepts banking and insurance involving learning of liability and corporate risk. MBA in Banking & Insurance covers the study of the fundamental concepts of management with specialised training in subjects for example International Banking and Insurance, Risk Management, Treasury Operations, Project & Infrastructure, Investment Banking, etc.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA in Banking and Insurance Management is a method for assurance of financial loss, provides applicants with a basic knowledge of the Banking and insurance markets,  with knowledge-based learning to the financial world at large, for example, wealth management, portfolio management, investment banking, financial and business consulting, risk management, financial and investment research, and general and life insurance services.
        </p>
        <b className="table-details">Table of content</b>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#1">	MBA in Banking & Insurance  – Program Highlights</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#2">	MBA in Banking & Insurance  – Syllabus</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#3">	MBA in Banking & Insurance : Skills Required</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#4">	MBA in Banking & Insurance : Job Profiles Offered</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#5">Top Colleges in India for 	MBA in Banking & Insurance </a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#6">	MBA in Banking & Insurance  - Top Recruiters</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#7">FAQs – 	MBA in Banking & Insurance </a>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Banking & Insurance  – Program Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Parameters
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Program Type
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation Degree with minimum 50 per cent in any discipline
                  (45 per cent for reserved category)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Test followed by GD – WAT – PI
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT, XAT, CMAT, MAT, NMAT, etc
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Program Options
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full Time, Part Time, Distance, Online
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Insurance Management
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Accounting for Managers
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Principles & Practices of Banking
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Merchant Banking & Financial Services
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Strategic Credit management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Fees
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 50,000 to Rs. 20 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 4 Lac to 12 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Job Profiles
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Financial Analyst
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Credit & Risk Manager
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Loan Counsellor and Sales Officer
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Credit Analyst
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Investment Banker
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <CustomTable />

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Banking & Insurance – Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from the core subjects, candidates are taught the following subjects in MBA / PGDM in Banking & Insurance specialisation programme:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Insurance Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Insurance Products
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Accounting for Managers
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Principles & Practices of Banking
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Banking & Insurance Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Merchant Banking & Financial Services
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Marketing of Banking Services
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Counter Operations, Savings Bank, Current Account and Remittances
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Retail Insurance Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Strategic Credit management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Legal Aspect of Banking
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Corporate Insurance management
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Treasury & Risk Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mutual Funds and Portfolio Management
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            <b>MBA in Banking & Insurance: Skills Required</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            Banking & Insurance is a dynamic sector and one needs to be versatile for job opportunities in this sector. Some of the essential skills are needed to excel in this sector are:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Accounting
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Financial reporting
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Problem-solving
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Reasoning skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Critical thinking
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Analytical ability
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Innovative
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            8.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Leadership qualities
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            <b>MBA in Banking & Insurance: Job Profiles Offered</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            This field is full of career opportunities with secure future. Various banks and multinational organizations offer multitude of banking job opportunities throughout the year. Some of the job roles offered are:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Financial Analyst
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Credit & Risk Manager
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Loan Counsellor and Sales Officer
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Credit Analyst
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Investment Banker
          </p>


          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Stock Analyst
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Asset Manager
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Insurance Manager
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Risk Manager
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Accountants and Auditor
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Personal Financial Advisor
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top Colleges in India for MBA in Banking & Insurance</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          With the growth in Indian economy, MBA in Banking & Insurance has become one of the most preferred specialization choice among MBA candidates. Some of the top colleges offering this program are International Management Institute- New Delhi, Institute of Financial Management and Research - Chittoor, ICFAI Business School- Hyderabad, International School of Business and Research - Bangalore, Institute of Public Enterprise – Hyderabad. To find out about the top colleges of India offering MBA / PGDM in Banking & Insurance,{" "}
          <span style={{ color: "red" }}>
            <Link to="/college-list?m=Top Private MBA Colleges">
              click here
            </Link>
          </span>.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Banking & Insurance - Top Recruiters  </b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            After completing MBA / PGDM in Banking & Insurance career opportunities are abundant in sectors like banking, finance, accounting, insurance, asset management etc. Here is the list of some of the top recruiting companies in this category:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            ICICI Bank
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            HDFC Bank
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            General Insurance Corporation
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            ICICI Prudential, Life Insurance
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Tata AIG Life
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            ICICI Lombard
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Bharti AXA
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            HDFC Standard Life- Insurance Co.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            SBI Life Insurance
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Life Insurance Corporation of India
          </p>

        </div>

        <CustomTable />
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>FAQs – MBA in Banking & Insurance</b>
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the eligibility to pursue MBA in Banking & Finance?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Anyone with a valid graduation degree in any discipline with minimum 50 per cent marks is eiligible to pursue MBA / PGDM in Banking & Insurance. For reserved category students, the minimum mark is 45 per cent.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Can I pursue an MBA in Banking & Finance course online?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. Yes, MBA in Banking & Insurance course can be pursued in online mode. There are universities offering it in Part-time and distance mode also.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the average cost of admission to MBA in Banking & Insurance?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. The average cost of pursuing MBA in Banking & Insurance from a government college is Rs. 1 Lac, whereas the fee of a private college is in the range of Rs. 6 to Rs. 20 Lac.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the difference between MBA in Banking & Finance and MBA in Banking & Insurance?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. MBA in Banking & Insurance is a banking based course which covers the concepts and rules of banking and insurance. The course involves advance learning of liability and corporate risk. On the other hand, MBA in Banking and Finance is a program which provides technical, expert as well as practical knowledge of banking and finance along with the combinational management subjects for business as well.
        </p>
        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are some of the top jobs for MBA in Banking & Insurance graduates?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. MBA / PGDM in Banking & Insurance provides comprehensive career scope to a candidate with job options in various industries like Colleges & Universities, Banks, IT Companies, Multinational Organizations, Insurance Departments, Media Houses etc.. Some of the top jobs on offer are Financial Analyst, Credit & Risk Manager, Credit Analyst, Investment Banker, Stock Analyst, Asset Manager, Insurance Manager, Risk Manager, etc.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the average salary of a fresher after completing MBA / PGDM in Banking & Insurance?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The average salary of MBA / PGDM in Banking & Insurance fresher ranges between Rs. 4 Lac to Rs. 12 Lac per annum.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the admission process for MBA / PGDM in Banking & Insurance course?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Admission process is mostly dependent on the B-school a student is targeting. In general the admission processed comprises of entrance test followed up by group discussion and personal interview.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Which are the top colleges for MBA in Banking & Finance in India?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Top colleges offering this program are International Management Institute- New Delhi, Institute of Financial Management and Research - Chittoor, ICFAI Business School- Hyderabad, International School of Business and Research - Bangalore, Institute of Public Enterprise – Hyderabad.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What do you study in MBA / PGDM in Banking & Insurance?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Apart from the core subjects, students of Banking & Insurance has to study Insurance Management, Insurance Products, Accounting for Managers, Principles & Practices of Banking, Banking & Insurance Management, Merchant Banking & Financial Services, Marketing of banking services, Retail Insurance management, Strategic Credit management, Legal Aspect of Banking, Corporate Insurance management, Treasury & Risk Management, Mutual funds and portfolio management.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Which are the top companies hiring MBA / PGDM in Banking & Insurance graduates?
        </p>
        <p
          className="MsoNormal"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: MBA / PGDM in Banking & Insurance graduates are hired in multiple sector like banking, finance, accounting, insurance, asset management etc. Top recruiting companies in this category are ICICI Bank, HDFC Bank, General Insurance Corporation, ICICI Prudential, Life Insurance, Tata AIG Life, ICICI Lombard, Bharti AXA, HDFC Standard Life- Insurance Co., SBI Life Insurance, Life Insurance Corporation of India etc.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Which are the top entrance exams for MBA / PGDM in Banking & Insurance?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The top entrance exams for MBA in Banking & Insurance are CAT, XAT, SNAP, NMAT, MAT, CMAT, and GMAT.
        </p>
      </div>
    </div>
  );
};
export default BankingInsurance;
