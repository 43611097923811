import { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../CustomTable";

const MCA = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Master of Computer Application (MCA) is a postgraduate course that trains students in the various aspects of computer programs, application software, computer architecture, operating systems, and many more. It’s a two-year professional post-graduate course for candidates wanting to delve deeper into the world of Computer Application development with the help of learning modern programming language. The program is a blend of both theoretical and practical knowledge. MCA endows students’ an opportunity to work with tools meant to develop better and faster applications.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MCA allows learners to specialise in the various aspects of computer applications such as cloud computing, android, etc. Students learn about the advanced applications of computer hardware and software and its application in various fields such as operating systems, web technology and computer languages such as Java, HTML, C++ etc. Anyone who wishes to delve deeper into the nuances of computer software and hardware as well as programming languages can pursue MCA course. Candidates can also get into academics or research in Data Analytics after completing MCA.
        </p>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#1">1.	MCA Course Highlights</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#2">2.	Why do MCA?</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#3">3.	Who can pursue MCA?</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#4">4.	Scope of MCA?</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#5">5.	MCA Eligibility Criteria</a>
        </p>
        <div>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              marginBottom: "10px"
            }}
          ><a className="link" href="#6">6.	MCA Admissions</a>
          </p>

          <ul style={{
            marginBottom: "16px",
          }}>
            <li className=" MsoListParagraphCxSpFirst"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                marginBottom: "16px",
                fontSize: "15px"
              }}>a.	Admission Through Entrance Exam</li>
            <li className=" MsoListParagraphCxSpFirst"
              style={{
                textAlign: "justify",
                fontSize: "15px",
                textJustify: "inter-ideograph",
              }}>b.	Direct Admission</li>
          </ul>

        </div>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#7">7.	MCA Specialisations</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#7">8.	MCA Syllabus</a>
        </p><p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#7">8.	MCA Syllabus</a>
        </p>
        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#7">10.	MCA - Top Recruiters</a>
        </p>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#7">11.	Frequently Asked Question (FAQs) – MCA</a>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MCA Course Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Particulars
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Course Name
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  MCA
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Full Form
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Master of Computer Application
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Course Level
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Postgraduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >MCA Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Candidates will have to graduate from BCA or equivalent with at least 50% aggregate marks or equivalent
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  MCA Course Duration
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 Years
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  MCA Course Fee
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  INR 1 Lakh to 4 lakh
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Types of MCA
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full-time MCA
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Distance/ Correspondence MCA
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Online MCA
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  MCA Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance-Based and Merit-Based
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Top MCA Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  NIMCET, OJEE, KMAT, CUET
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  INR 4 LPA - INR 7 LPA
                </p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal", fontWeight: "bold",
                  }}
                >
                  Job Positions
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Software Developer, Systems Analyst, IT Consultant, Network Engineer
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>


        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            <b>Why do MCA?</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            MCA programme is comprehensive and structured in a way that best suits the IT industry. Depending on the expertise after the completion of the MCA, a student can apply to various industries such as network, hardware and software development. Master of Computer Applications is thriving with endless opportunities in both the public and private sectors.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>The IT sector is growing rapidly in India and as per reports more than 2,00,000 new job opportunities are being created every year.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>	MCA course candidates have unlimited job opportunities in a variety of industries/sectors as well as job roles.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>	MCA candidates can also expect to get hired by the world-best MNCs and IT companies such as Google, Microsoft, Amazon, etc.
          </p>

        </div>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Who can pursue MCA?</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Candidates who wish to work in the IT industry should enroll in the MCA program. BCA degree student wanting to broaden their academic horizon by adding one more degree, should enroll for the MCA course to advance their career. Candidates interested in making a career as a computer programmer, software developer, app developer, data analyst, or system analyst, then a Master of Computer Applications (MCA) is the right option. The majority of MCA graduates work in application and software development fields.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Scope of MCA?</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          With IT being one of the fastest expanding industries, MCA has emerged as one of the top courses for pursuing professional chances. There are a variety of options after completing an MCA course. Candidates can also get into academics or research in Data Analytics after completing MCA. Professionals can work as App Developers, Ethical Hackers, Manual Testers, Trouble-shooters for various IT Services-based companies.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MCA Eligibility Criteria </b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            MCA eligibility criteria include subjects required, educational qualification, age limit, etc. Candidates can check the eligibility criteria for the MCA course given below:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Candidates must have a graduate degree in computer science (B. Sc. IT) or computer applications (BCA) with an aggregate mark of 50% or equivalent from a recognized university.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            In some colleges, candidates must have mathematics in class 12 as one of the subjects.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            There are no upper age limit restrictions to enroll in MCA course.
          </p>

        </div>


        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MCA Admissions </b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            The admission to MCA courses usually takes place through various national, state or university level entrance exams. Direct admission is also available on the basis of academic merit.
          </p>

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <u>   Admission Through Entrance Exam -</u> Following are the steps included for admission to MCA course through the entrance exam:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Candidates needed to visit the official website of the entrance exam board or university and register themselves.
          </p>
          <p
            className="cont"
            style={{
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Candidates are required to insert their application details, upload the prescribed documents as per the size and format requirements and then pay the application fees.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Candidates are then required to download the admit card and appear for the desired entrance examination.
          </p>

          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Successful candidates are summoned for a counseling session for MCA. Candidates are admitted after an allotment of seats in each round of counseling.
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <u>Direct Admission - </u> Following are the steps included for admission to MCA course through Direct Admission:
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Direct admission for MCA is done on the basis of merit in qualifying exams, as well as academic merit.
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Some institutes also provide admission on management quota, which is also one way of direct admission.
          </p>

          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Management quota seats in every college are reserved. The fee is generally higher for these seats than for general seats as candidates have to pay extra fees.
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MCA Specialisations</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          There are diverse specializations in MCA degrees from which candidates can choose. Some of the popular specialisations offered as electives in the final year are:
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Application Software
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >Systems Management</p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hardware Technology</p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Cloud Computing</p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Management Information Systems (MIS)</p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Software Development</p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Systems Engineering</p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Cyber Security</p>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mobile Computing</p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Data Analytics</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MCA Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Following is a suggestive list of subjects taught during the MCA course. This include, both practical subjects and theory subjects.
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Semester I
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Semester II
                  </b>
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Semester III
                  </b>
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Semester IV
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Introduction in IT
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Operating Systems
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Operating Systems and Systems Software
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Software Engineering & TQM
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Computer Organisation & Architecture
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Oral and Wireless Communications
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Unix and Shell Programming
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Environment and Ecology
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Programming & Data Structure
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Info Systems Analysis Design & Implementations
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Statistics and Numerical Techniques
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graphics and Multimedia
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Introduction to Management functions
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Accounting and Management Control
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Intelligent Systems
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Operation Research and Optimisation Techniques
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MCA Career Scope and Job Profiles</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          An MCA degree holder with the right amount of relevant work experience, skill set and calibre can easily find great job opportunities at leading IT firms (both private and government) across India and abroad.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The salary offered to MCA graduates varies job level, to work experience and skills of the professional. With the starting salary ranging from Rs. 3 – 4 LPA, those who have minimum experience of 3-5 years can expect to earn in the range of Rs. 8 - 12 LPA in top companies. Some of the profiles offered are of App Developer, Database Engineer, Ethical Hacker, Hardware Engineer, Web Designer/Developer, etc.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MCA - Top Recruiters</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MCA graduates are hired by top multinational companies working in the field of IT and Software, Customer Service, Banking and Investment, Business Process or Knowledge Process Outsourcing, etc. Professionals are hired on the basis of their experience and skills in order to address to the issues pertaining to their critical infrastructure and provide solutions to any issues. Some of the major recruiters are:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Infosys
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Microsoft
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Oracle
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                 TCS
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Polaris
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Wipro
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Capgemini
                </p>
              </td>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Cognizant
                </p>
              </td><td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Accenture
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Frequently Asked Question (FAQs) – MCA</b>
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the eligibility for MCA course?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: A student must minimum of 50% in BCA / B.Sc / B.Com / BA degree from a recognised university with Mathematics at the 10+2 level.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What career opportunities are associated with MCA?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. MCA course give students an understanding about how to develop better computer applications and software. Candidate with high computer skills are in high demand in the IT sector as well as government sector. Candidates can get jobs in areas such as banking, networking, IT companies, desktop publishing, and more. Candidates can work on live projects, entry level programmer and maintaining software/application. Candidate seeking options in the teaching profession with an MCA degree is also an option.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is an average salary of MCA graduates?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. The salary package of MCA graduates ranges from 4.5 to 6.5 lakhs per annum. The salary of a candidate also depends on work organisation and education skills. Both the private and government companies provide jobs to qualified MCA course graduates. Salary structure for professionals also depend on the entry level as well as the experience and skills of the individuals. Professionals can expect a salary of INR 6- 12 LPA who possess a minimum work experience of 3-5 years.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are the job options available for MCA graduates?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. MCA graduates can apply for profiles like Software Developer, Computer System Analyst, Software Engineer or Programmer, Database Administrator, Software Publisher, Software Application Architect, Software Consultant, Hardware Engineer, Web Designer/Web Developer, Senior Technical Consultant and Project Manager. There will be other profiles as well depending on the candidate’s previous work experience.
        </p>
        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are the subjects offered under MCA?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans. The MCA course is divided into four semesters. Some of the subjects listed in the curriculum include:
          <div className="required-skills mt-3">
            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Database Management System
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Object Oriented Programming
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Mathematical Foundations
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Computer Science Foundations
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Software Engineering
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Web Technologies
            </p>
          </div>
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Can MCA be completed in distance mode?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, MCA can also be pursued in distance learning mode. Candidates must meet the eligibility requirements. Must have BCA / B. Sc. in Computer Science from recognised university with minimum 50% in graduation.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are some of the top recruiters for MCA?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Top recruiters for MCA professionals include IT based companies and multinational companies working in the field of software technology and service sector such as:

          <div className="required-skills mt-3">
            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              TCS
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              NTPC
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              IBM
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Cognizant
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Accenture
            </p>

            <p
              className="cont"
              style={{
                textAlign: "justify",
                textJustify: "inter-ideograph",
                textIndent: "-0.25in",
              }}
            >
              <span style={{ fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              Oracle
            </p>
          </div>
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is MCA Cyber Security Course?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Cyber Security is a specialization offered in MCA program. Candidates are offered various core subjects related to Cyber Security such as Network Security, Python Scripting, Fundamentals of Cybersecurity Operations, Cybersecurity Governance etc.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is best MCA or MBA?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Both MCA and MBA course have their merits and it is on the student what he wants to do in the future. If he wants a job and is good at administrative skills then he must go forward with an MBA but if he wants to learn more about MCA course as a subject then MCA course is the way forward.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Is MCA course equal to BTech?
        </p>
        <p
          className="MsoNormal"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: MCA course in general is regarded as equivalent to B. Tech. but M. Tech. has more scope than MCA. It would be better if the student studying MCA course remains in the academic field where his knowledge will be given more importance.
        </p>

        <p
          className="headings cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Can I obtain a government job after finishing my MCA?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, one can secure a government job after completing an MCA degree. Students are qualified for specialized jobs, like IT officer positions, and other IT positions in the banking, railway, and postal sectors. Candidates can join ISRO and DRDO through its scientist and researcher recruiting procedure too.
        </p>
      </div>
    </div>
  );
};
export default MCA;