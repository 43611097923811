import { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../CustomTable";
const AdvertisingCommunication = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Advertising &amp; Communication</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The primary job of an advertising professional is to convey messages
          to people and initiate them to buy products/ services. Advertising
          courses are offered under the umbrella of the mass communication
          domain and includes study of Public Relations and Communications. All
          these work together as a medium of communication with potential
          consumers of a product or service.
        </p>

        <b className="table-details">Table of content</b>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#1">
            MBA In Advertising &amp; Communication Management  – Program Highlights</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#2">
            MBA In Advertising &amp; Communication Management   – Syllabus</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        ><a className="link" href="#3">
            MBA In Advertising &amp; Communication Management  : Skills Required</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#4">
            MBA In Advertising &amp; Communication Management : Job Profiles Offered</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >

          Top Colleges in India for MBA In Marketing
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#6">
            MBA In Advertising &amp; Communication Management  - Top Recruiters
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#7">
            FAQs – MBA In Advertising &amp; Communication Management
          </a>
        </p>

        <p
          className="MsoNormal" id="1"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Advertising &amp; Communication – Program Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Parameters
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program Type
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation Degree with minimum 50 per cent in any discipline
                  (45 per cent for reserved category)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Test followed by GD – WAT – PI
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT, XAT, CMAT, MAT, etc
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program Options
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full Time, Distance, Online
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Introduction to Communication
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Introduction to Advertising
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Principles of Public Relations
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Media Planning
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Oral and Visual Communication
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Fees
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 50,000 to Rs. 10 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 3 Lac to 8 Lac per annum
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Job Profiles
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Media Relation Officer
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Advertising Media Planner
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  PR Executive
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Content Developer
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />

        <p
          className="MsoNormal" id="2"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Advertising &amp; Communication – Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from the core subjects, candidates are taught the following
          subjects in MBA / PGDM in Advertising &amp; Communication
          specialisation programme:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Advertising Principles, Concepts, and Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Media Planning
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Creativity and Campaign Planning
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Public Relations &amp; Corporate Communication
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Government and Public Service Communication
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  New Media: Concepts and Applications
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Production Techniques &amp; Methods
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Advertising, Marketing, and PR Research
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Creating Advertisements
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Oral and Visual Communication
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Communication Theory and Research Marketing
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Digital Public Relations and Corporate Communication
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal" id="3"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Advertising &amp; Communication: Skills Required</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            To pursue MBA / PGDM in Advertising &amp; Communication course, a
            candidate must be enthusiastic, creative, confident, optimistic, and a
            multi-tasker. Skills and traits that a candidate needs for pursuing an
            advertising course are:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Creativity
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Innovation
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Teamwork
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Ability to meet stringent deadlines
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Client handling
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            People skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Persuasion skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Communication skills
          </p>

        </div>

        <p
          className="MsoNormal" id="4"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>


        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Advertising &amp; Communication: Job Profiles Offered</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Advertising, PR and Communication professionals are mainly available
            in advertising agencies and advertising departments of private and
            public sector firms. Candidates can also find jobs in newspapers,
            magazines, journals, commercial sections of radio and TV, market
            research firms, etc.
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Creative Technologist
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Copywriter
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Creative Director
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Art Director
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Brand Strategist
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Media Planner
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Public Relations Specialist
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            Top Colleges in India for MBA in Advertising &amp; Communication
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Some of the top colleges offering MBA / PGDM in Advertising &amp;
          Communication are Mudra Institute of Communications, Ahmedabad, Indian
          Institute of Mass Communication, New Delhi, Sir Aurobindo Centre of
          Arts and Communication, New Delhi, Jagran Institute of Management and
          Mass Communication, New Delhi , International School of Business &amp;
          Media, Pune <span style={{ color: "red" }}><Link to="/college-list?m=Top Private MBA Colleges">
            click here
          </Link></span>.
        </p>
        <p
          className="MsoNormal" id="6"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Advertising &amp; Communication - Top Recruiters </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            Any advertising professional dreams of working with the top ad groups
            of India, such as:
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Hindustan Thompson Associates (HTA)
          </p>


          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>McCann Erickson India Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>TLG India Pvt Ltd (Leo Burnett)
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>R K Swamy / BBDO Advertising Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Rediffusion Dy &amp; R Pvt Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Mullen Lowe Lintas Group
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Ogilvy and Mather Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Mudra Communications Pvt Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>JWT-IndiaTriverse Advertising
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>FCB-Ulka Advertising Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Chaitra Leo Burnett Pvt Ltd
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Dentsu Aegis
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>GroupM
          </p>
        </div>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />
        <p
          className="MsoNormal" id="7"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>FAQs – MBA in Advertising &amp; Communication</b>
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the various modes of advertising course?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Advertising aspirants can pursue course related to advertising in
          UG, PG and certificate modes.&nbsp; So it is available in full time,
          part time, distance, online as well as certificate based programs.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What qualification is required to pursue an Advertising degree
          course?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Candidates who wish for a UG course in advertising like BBA in
          Advertising, BA in Advertising must have completed Class 12 with a
          minimum of 45 per cent. For PG degree / diploma courses majoring in
          Advertising, candidates must have completed a bachelor's degree course
          or equivalent in any discipline with minimum 50 per cent marks (45 per
          cent marks for reserved category students).
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Is advertising a good career option?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Advertising is a lucrative, stable and charming career option. On
          ground, Mobile, Print, Digital and TV advertising has been on a growth
          and hence candidates can find several job opportunities in the
          advertising industry.
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the fee of MBA in Advertising &amp; Communication?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A: Colleges offering MBA / PGDM in Advertising &amp; Communication are
          limited. Fees range of the colleges is between Rs. 50,000 to Rs. 10
          Lac.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Does Google Ads certification provide in-depth knowledge of
          Advertisement?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Google Ads certification is a professional accreditation offered
          by Google to impart knowledge on how ad network works on google.
          Candidates who wish for an advertising certification course to learn
          basic concepts of digital advertisement space can pursue Google Ads
          certification.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the salary of fresher’s after completing MBA in Advertising
          &amp; Communication?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: A fresher’s salary after completing MBA in Advertising &amp;
          Communication ranges between Rs. 4 – 5 Lacs per annum. However, with
          experience advertising industry is very lucarative.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the difference between MBA in Marketing and MBA in
          Advertising &amp; Communication?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: MBA in Marketing is a broad subject with focus on multiple
          aspects of marketing such as market research, product development,
          brand alignment, etc. MBA in Advertising &amp; Communication primarily
          deals with the promotion of a product, service, or brand.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top colleges for MBA in Import &amp; Export in India?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Some of the top colleges offering MBA / PGDM in Advertising &amp;
          Communication are Mudra Institute of Communications, Ahmedabad, Indian
          Institute of Mass Communication, New Delhi, Sir Aurobindo Centre of
          Arts and Communication, New Delhi, Jagran Institute of Management and
          Mass Communication, New Delhi , International School of Business &amp;
          Media, Pune. To view list of colleges offering MBA / PGDM in
          Advertising &amp; Communication,{" "}
          <span style={{ color: "red" }}><Link to="/college-list?m=Top Private MBA Colleges">
            click here
          </Link></span>.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the job profiles in the field of advertising?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Some of the top job profiles in the field of advertising include
          copywriter, production coordinator, art director, event planner,
          marketing coordinator, creative director, graphic designer etc.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Is there any online advertising degree course?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, MBA in Advertising &amp; Communication can also be pursued
          online. Apart from this there are many certificates based advertising
          course available online.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top companies for recruitment for MBA / PGDM in
          Advertising &amp; Communication?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Any advertising professional dreams of working with the top ad
          groups of India, such as Hindustan Thompson Associates (HTA), McCann
          Erickson India Ltd, TLG India Pvt Ltd (Leo Burnett), R K Swamy / BBDO
          Advertising Ltd, Rediffusion Dy &amp; R Pvt Ltd, Mullen Lowe Lintas
          Group, Ogilvy and Mather Ltd, etc. Candidates can also find jobs in
          newspapers, magazines, journals, commercial sections of radio and TV,
          market research firms, etc.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top entrance exams for MBA / PGDM in Advertising
          &amp; Communication?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The top entrance exams for MBA / PGDM in Advertising &amp;
          Communication are CAT, XAT, SNAP, MAT, CMAT, and GMAT.
        </p>
      </div>
    </div>
  );
};
export default AdvertisingCommunication;
