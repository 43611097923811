import CustomTable from "../../../CustomTable";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const CAT2022 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="WordSection1">
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            CAT 2022 Exam on Nov 27: Registration Open; Check Eligibility,
            Syllabus, Test Pattern, Prepare Tips for CAT
          </b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 examination will be conducted by IIM Bangalore on November
          27, 2022. Similar to previous year, this year it will be conducted in
          three sessions in 150 plus cities across India. Registration for CAT
          2022 has already begun from 3<sup>rd</sup>
          August and will go on till 14<sup>th</sup> September 14, 2022.
          Application fee for CAT has been raised this year.{" "}
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Registrations are open at official CAT website iimcat.ac.in with an
          application fee of Rs. 2,300 for General candidates and Rs. 1,150 for
          reserved category candidates (SC, ST and PwD category candidates). CAT
          2022 duration will be of two hours with sectional time limit of 40
          minutes for each section. Candidates will have to solve approximately
          66 objective-type questions in the stipulated time.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <CustomTable />
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <b className="table-details">Table of content</b>

        <div className="required-skills">

          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            <a href="#1">About CAT Examination</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#2">CAT Examination 2022 – Highlights</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#3">CAT 2022 Important Dates</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#4">Which IIM Conducts CAT Exam?</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#5">
              CAT <a name="OLE_LINK1">Eligibility </a>Criteria 2022
            </a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#6">CAT Registrations Process 2022</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#6a">CAT Registration Trend </a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#7">CAT 2022 Exam Centers</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#8">CAT exam Syllabus and Pattern</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#9">CAT 2022 Exam Preparation Tips</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#9a">
              CAT Preparation for Data Interpretation and Logical Reasoning (DILR)
            </a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#9b">CAT Preparation for Quantitative Aptitude (QA)</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#9c">
              CAT Preparation for Verbal Ability and Reading Comprehension (VARC)
            </a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#10">CAT 2022 Admit Card</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#11">CAT Exam Day Guidelines</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#12">CAT Answer Key 2022</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#13">CAT 2022 Result and Scorecard</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#14">CAT Score vs Percentile</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#15">CAT 2022 Result &amp; IIMs’ Final Selection Process</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#15a">Top IIMs Conduct Individual Admission Process</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#15b">Common Admission Process (CAP) for 9 IIMs</a>{" "}
            <a href="#15c">
              IIM Nagpur, IIM Rohtak, IIM Visakhapatnam to conduct individual
              admission process
            </a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#16">IIM CAT Cut Off Percentiles</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#17">Admission to Top Non-IIMs through CAT scores</a>
          </p>
          <p
            className=" MsoListParagraphCxSpFirst"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}>
            <a href="#18">FAQs CAT Exam 2022</a>
          </p>
        </div>

        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>About CAT Examination</b>
        </p>
        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT stands for Common Admission Test. It is a national-level post
          graduate management course entrance examination for MBA / PGDM. The
          test is conducted by any of the top 6 Indian Institute Management
          (IIM) every year for admission in 20 IIMs and 2000+ other CAT score
          accepting B-schools. Every year around 2,00,000 candidates apply for
          CAT exam to get admission to 5000+ MBA / PGP seats of IIM’s.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from IIM’s, CAT is also a mandatory entrance exam for MBA
          admission in colleges like FMS Delhi, SPJIMR Mumbai, MDI Gurgaon, IIT
          Bombay, IIT Delhi, IIT Kharagpur; NITIE Mumbai and hundreds of other
          top B-schools in India. Salient features of CAT exam are:
        </p>


        <div className="required-skills">
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            It is a computer based test{" "}
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            It is conducted at more than 150 cities with approximately 400+ test
            venues
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            CAT exam duration is reduced to two hours from earlier three hours
            since 2020
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Subject wise the exam is divided into three sections:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Verbal Ability &amp; Reading Comprehension (VARC)
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Data Interpretation &amp; Logical Reasoning (DILR)
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Quantitative Ability (QA)
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Each section is to be attempted in a fixed individual time of 40
            minutes
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Indian Institutes of Management (IIMs) will offer admission in 2023 on
            the basis of CAT 2022 score only{" "}
          </p>
        </div>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          According to 2021 official website of CAT, around 2.30 lakhs eligible
          candidates registered for the CAT entrance test. The overall CAT
          test-takers attendance was approximately 83% at about 1.92 Lac
          candidates. Out of total appeared candidates 65% were males and 35%
          were females, and 2 candidates represent transgender community.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Examination 2022 – Highlights</b>
        </p>

        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>

        <table cellSpacing={0} cellPadding={0} align="left" className="shadow-none mb-5">
          <tbody>
            <tr>
              <td width={1} height={1} />
            </tr>
            <tr>
              <td />
              <td>
                <img
                  src="1.%20CAT%202022_files/image001.jpg"
                  width={628}
                  height={480}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Particulars
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT Full Form
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Common Admission Test
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Conducting institute in 2022
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Bangalore
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Convener
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Ashis Mishra
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Level
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  National level Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Language
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  English
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Frequency
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Once in a Year
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Date
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nov 27, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Fees
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 2,300 (General category)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 1,150 (Reserved category)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Eligibility Criteria
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation with 50 % aggregate (45 % for SC, ST and PwD
                  category candidates)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Number of Applications
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2.31 Lac (in 2021)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Number of Test Takers
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1.92 Lac (in 2021)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Purpose
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission into IIMs, SPJIMR, FMS, MDI, IMT and other 2000 plus
                  top B-schools of India.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Course Offered
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  MBA
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  PGP
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  PGDM
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Verbal Ability &amp; Reading Comprehension (VARC)
                </p>
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Data Interpretation &amp; Logical Reasoning (DILR)
                </p>
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative Ability (QA)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT Pattern
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Computer Based Test (CBT) with MCQs and non-MCQs
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No of Questions
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  66
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No. of Test Cities
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  150 plus
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT Official Website
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <a href="https://iimcat.ac.in">https://iimcat.ac.in</a>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Helpdesk No.
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1-800-210-1088 (Toll Free)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <a href="mailto:cathelpdesk@iimcat.ac.in">
                    cathelpdesk@iimcat.ac.in
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Age Criteria
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No Age Limit
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Work Experience
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Not Mandatory
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>CAT 2022 Important Dates</b>
        </p>
        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    CAT Exam 2022 Events
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Important Dates
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Notification{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  31<sup>st</sup> July, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Registration Start
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  3<sup>rd</sup> August, 2022 (10:00 AM)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Registration End
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  14<sup>th</sup> September, 2022 (5:00 PM)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Form Correction Window
                  Opens&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Last week of September 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mock Test Release
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Fourth week of October 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admit Card Release
                  Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  27<sup>th</sup> October, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Examination
                  Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  27<sup>th</sup> November, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Answer Key and Response Sheet Release{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  First week of December 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Answer Key Challenge
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  First week of December 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT Result{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Second week of January 2023
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Which IIM Conducts CAT Exam? </b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT examination is conducted by one of the top six IIMs every year on
          a rotation basis. This year, IIM-Bangalore will be conducting CAT. Dr
          Ashis Mishra is the Exam Convener&nbsp;&nbsp; for CAT 2022. Below
          table shows the details of which IIM has conducted the CAT exam in
          recent years and the exam convener:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    CAT Exam Year
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    CAT Exam Convener
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    CAT Conducting IIM
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2022
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Ashis Mishra
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Bangalore
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2021
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr M P Ram Mohan
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Ahmedabad
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2020
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Harshal Lowalekar
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Indore
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2019
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Shubhasis Dey
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Kozhikode
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2018
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Sumanta Basu
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Calcutta
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2017
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Neeraj Dwivedi
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Lucknow
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "84.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2016
                </p>
              </td>
              <td
                style={{
                  width: "111.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={148}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dr Rajendra K Bandi
                </p>
              </td>
              <td
                style={{
                  width: "106.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={142}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IIM Bangalore
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Eligibility Criteria 2022</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The minimum eligibility criterion for CAT 2022 exam is graduation in
          any stream. Detailed CAT eligibility criteria are listed below:
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidates must hold a bachelor's degree with minimum 50 per cent
            aggregate or equivalent CGPA (45 per cent aggregate for SC, ST and PwD
            category candidates) from a recognised university to appear in CAT
            2022.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidates appearing for the final year of bachelor's degree /
            equivalent qualification or awaiting the result are also eligible to
            apply for CAT 2022 exam.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidates who have completed a professional degree (CA / CS / ICWA /
            FCAI) with the required percentage can also appear for CAT.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            There is no age limit for appearing in CAT 2022.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Work experience is not mandatory for appearing in CAT 2022.
          </p>
        </div>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <CustomTable />
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Registrations Process 2022</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 exam registrations commenced on August 3 at 10:00 AM and will
          end on September 14 at 05:00 PM. Candidates will have to register at
          the iimcat.ac.in website to fill the online application form. The
          process of registration and filling the application form of CAT 2022
          is listed below:{" "}
        </p>
        <div className="required-skills">
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Login to CAT website iimcat.ac.in{" "}
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Click on new registration
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            3.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Enter name, date of birth, nationality, phone number and email ID to
            register
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            4.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Log in with the ID received in email to fill the application form
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            5.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Fill in the Application Form in following steps:
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            a.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            &nbsp;Fill in Personal Details in Application Form
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            b.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Submit Academic Details from Class 10 onwards
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            c.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Fill in your Work Experience
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            d.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Upload a passport sized photo and scanned signature
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            e.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Select IIMs &amp; Programmes that you wish to apply
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            f.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Select your Preferred CAT Exam Cities ( IIMs offer a choice of 4 or 6
            test cities)
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            6.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Make Online payment of CAT Registration Fee
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            7.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Application Form is complete.
          </p>
        </div>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Registrations Trend</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT registration did show a significant decline for five years from
          2010 to 2014; where in 2013 and 2014 number of CAT registration fell
          below 200,000 students. From 2015 onwards there has been a rise in
          registration count reflecting the regaining trust of CAT aspirants and
          anticipation of better career opportunities after completing MBA / PGP
          / PGDM from an IIM or other top B-school.
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Year
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    No. of Applications registered
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    No. Of Applicants appeared
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2021
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2.30,000
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,91,660
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2020
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,28,000
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1.90,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2019
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,44,169
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,10,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2018
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,40,338
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,09,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2017
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,31,000
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,99,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2016
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,32,434
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,95,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2015
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,18,664
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,79,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2014
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,96,988
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,67,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2013
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,94,516
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,73,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2012
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,14,000
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,95,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2011
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,05,000
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,86,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "38.6pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={51}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2010
                </p>
              </td>
              <td
                style={{
                  width: "104.5pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={139}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2,04,000
                </p>
              </td>
              <td
                style={{
                  width: "97.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  1,85,000
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont mb-0"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Note: CAT appeared student has been rounded off.
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT 2022 Exam Centers</b>
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 will be held in more than 150 cities of the country and each
          city will have one or more CAT exam centers. Final list of CAT 2022
          exam centers is expected to be out soon. CAT 2021 was conducted in 156
          cities at more than 400 exam centers. While registering for CAT, a
          candidate has the opportunity to choose from 6 cities. Given below is
          a list CAT 2022 exam centers:
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    State
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Name of the City
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Andaman &amp; Nicobar Islands
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Port Blair
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Andhra Pradesh{" "}
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Guntur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kakinada
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kurnool
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nellore
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rajahmundry
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tirupathi
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vijayawada
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Visakhapatnam
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vizianagaram
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Arunachal Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Itanagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Naharlagun
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Assam
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dibrugarh
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Guwahati
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jorhat
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Silchar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tezpur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bihar
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Arrah
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhagalpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Darbhanga
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gaya
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Muzaffarpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Patna
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chandigarh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chandigarh
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chattisgarh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhilai Nagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bilaspur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Raipur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dadar and Nagar Haveli, Daman &amp; Diu
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Silvassa
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Goa
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Madgaon
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Panaji
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gujarat
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ahmedabad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Anand
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gandhinagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mehsana
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rajkot
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Surat
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vadodara
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Haryana
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ambala
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Faridabad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gurugram
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Himachal Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bilaspur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hamirpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Shimla
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jammu &amp; Kashmir
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jammu
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Srinagar
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jharkhand
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dhanbad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hazaribagh
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jamshedpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ranchi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Karnataka
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Belagavi(Belgaum)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bengaluru
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hubli-Dharwad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kalaburagi(Gulbarga)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mangaluru(Mangalore)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mysuru(Mysore)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Shivamogga(Shimoga)
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Udupi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kerala
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kannur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ernakulam
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kollam
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kottayam
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kozhikode
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Thrissur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Trivandrum
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ladakh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Leh
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Lakshadweep
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kavaratti
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Madhya Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhopal
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gwalior
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Indore
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jabalpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Sagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Satna
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ujjain
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Maharashtra
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ahmednagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Amravati
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Aurangabad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhiwandi
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Boisar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jalgaon
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kolhapur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mumbai
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nagpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nanded
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nashik
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Navi Mumbai
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Palghar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Pune
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Raigad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Satara
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Solapur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Thane
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vasai
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Manipur
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ukhrul
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Meghalaya
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Shillong
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mizoram
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Aizawl
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nagaland
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kohima
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  New Delhi
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  New Delhi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Odisha
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Balasore
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Berhampur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhubaneswar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Cuttack
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dhenkanal
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rourkela
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Sambalpur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Puducherry
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Puducherry
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Punjab
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Amritsar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhatinda
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jalandhar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ludhiana
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mohali
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Patiala
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rajasthan
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ajmer
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bikaner
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jaipur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jodhpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kota
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Sikar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Udaipur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tamil Nadu
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chennai
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Coimbatore
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Madurai
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Salem
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tiruchirappalli
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tirunelveli
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vellore
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Telangana
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hyderabad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Karimnagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Warangal
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tripura
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Agartala
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Uttar Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Agra
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Aligarh
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bareilly
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ghaziabad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gorakhpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Greater Noida – Noida
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jhansi
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kanpur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Lucknow
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mathura
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Meerut
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Moradabad
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Muzaffarnagar
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Prayagraj
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Varanasi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Uttarakhand
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dehradun
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Haldwani
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Roorkee
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140.0pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  West Bengal
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Asansol
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bankura
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Burdwan
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Durgapur
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hooghly
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kalyani
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kolkata
                </p>
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Siliguri
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Exam Syllabus and Pattern</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Unlike other management exams where GK is also part of syllabus, the
          syllabus of CAT exam has remained the same for more than last ten
          years. The syllabus is still focused on Verbal Ability &amp; Reading
          Comprehension (VARC), Data Interpretation &amp; Logical Reasoning
          (DILR) and Quantitative Aptitude (QA) with equal importance to each
          section. The in-focus topics of CAT 2022 syllabus for exam preparation
          are:
        </p>

        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Syllabus
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    Topics
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Verbal Ability &amp; Reading Comprehension (VARC)
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Long &amp; Short Passages, Jumbled Paragraphs; Para-summary,
                  Picking the Out of context sentence from a jumbled paragraph
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Data Interpretation &amp; Logical Reasoning (DILR)
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Data Charts, Data Tables, Bar Graphs, Venn Diagrams, Seating
                  arrangement, Team formation, Clocks &amp; Calendars
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative Aptitude (QA)
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Arithmetic, Modern Math, Number System, Algebra, Mensuration,
                  Trigonometry, Geometry
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The current CAT exam pattern, exam duration, no of questions is as
          follows:
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Exam Duration is 120 Minutes: CAT 2022 is a computer delivered test
            with total time duration of 120 minutes.{" "}
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Section wise Time Duration: Total time of 120 minutes is divided into
            3 sections of 40 minutes each for VARC, DILR and QA.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            66 Questions: Like CAT 2021, CAT 2022 exam will have 66 questions
            divided in 3 sections of VARC, DILR and Quantitative Ability.{" "}
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            MCQ and Non-MCQ type of questions – In CAT 2022, approximately 48 – 50
            questions would be MCQ and 18 – 20 questions are expected to be
            non-MCQ.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Marking Scheme – MCQ carry 3 marks for every correct response with
            Negative marking of 1/3 (-1) for every wrong MCQ response. No negative
            marking for wrong non-MCQ response.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            CAT cut off Requirement by IIMs: A minimum score between 70 to 90
            percentiles in CAT 2022 would be needed in each of the 3 sections, for
            candidates to qualify for final admission round.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT 2022 Exam Preparation Tips</b>
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT is one of the most competitive entrance exams with more than 2 lac
          candidates competing for around 5000 seats of IIMs. Hence, it becomes
          essential to prepare well for the exam. To begin with CAT 2022
          preparation, one should solve &amp; analyze previous year question
          papers, go through the detailed subject-wise exam analysis and focus
          on covering at least 70% of the syllabus in 2-3 months. Some of the
          most important tip and tricks of CAT exam preparation include:
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Identify your strengths and weaknesses and make a list of topics as
            per it.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Join online study groups on platforms such as Facebook, YouTube,
            Telegram, etc.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Collect relevant study material, test sets and recommended books for
            CAT preparation.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Make day-wise plan as per topics and syllabus by allotting time to
            VARC, DILR and QA preparation.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Practice two-three questions from the topic studied on the same day to
            assess how much you have learnt.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Start from topics wise test series to be followed by subject wise and
            then overall.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Increase the speed gradually to cover maximum number of topics each
            day.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            CAT Preparation for Data Interpretation and Logical Reasoning (DILR)
          </b>
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Questions in Data Interpretation and Logical Reasoning (DILR) section
          are asked in the form of Sets of 4-5 questions. Cracking one set
          accurately can fetch you 12-15 marks. Important tips for preparation
          of DILR are:
        </p>
        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            For DI preparation, solve at least 5-6 sets every week from different
            types of data sets- bar graphs, line graphs, pie charts, tabular data,
            and caselets.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            To manage time while solving DI questions, be thorough with important
            ratio-percentage conversions, profit &amp; loss, averages, and other
            arithmetic topics.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            For LR preparation, pick one LR topic for a day and solve 20-30
            questions of varying difficulty.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Arun Sharma CAT Books can be referred to prepare for DILR and practice
            500+ questions.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Join online study groups on Facebook, Telegram, etc. and join CAT mock
            test series provided by coaching institutes.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Preparation for Quantitative Aptitude (QA)</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Arithmetic accounts for around 50% of the questions asked in CAT Quant
          section and the least number of questions are asked from Numbers and
          Modern Mathematics. A student should focus more on preparing for
          Arithmetic, Algebra, and Geometry.
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Be updated about the basic calculation concepts- ratio-percentage
            conversion, squares and cubes till 30, square roots &amp; cube roots,
            averages, exponential theorems, divisibility rules, concept of
            numbers, units digit, alligation, probability, P&amp;C, and tables
            till 25.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            For Arithmetic preparation, focus more on topics like Profit &amp;
            Loss, TSD, Time &amp; Work, Mixtures, Averages, and Percentages.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            In Geometry, the most important topics include Triangles, Circles, and
            Parallelograms along with important theorems and formulas on triangles
            and circles. Question can also be expected from Mensuration- both 2D
            and 3D.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Pick 2 topics every day and solve 15 questions on each topic daily.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Join online study groups on Facebook, Telegram, etc. and join CAT mock
            test series provided by coaching institutes.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Arun Sharma CAT Books can be referred to prepare for Quant and
            practice 500+ questions.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Attempt sectional mock tests in every 2 – 3 days with the time limit
            of 40 minutes. Review your performance after every mock test and work
            on your errors.
          </p>
        </div>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            CAT Preparation for Verbal Ability and Reading Comprehension (VARC)
          </b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Reading Comprehension (RC) accounts for more than 50% of the questions
          asked in Verbal Ability and Reading Comprehension (VARC). The
          important topics in Verbal Ability include para jumbles, odd one out,
          para summary, and error detection.
        </p>
        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            To increase your vocabulary, read newspapers, magazines, or online
            articles on a daily basis. You can also refer to the book- Word Power
            Made Easy by Norman Lewis.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Arun Sharma CAT Books can be referred to prepare for VARC and practice
            500+ questions.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            To increase reading speed for RC passages, start with short passages
            (250-350 words) and then gradually move to 500+ word passages.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Solve CAT previous year question papers, sample papers, and various
            online mock test series to get hold on a variety of questions.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <CustomTable />

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT 2022 Admit Card</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 Admit Card will be available on the official website for
          download on October 27, 2022. It is mandatory document to be carried
          to the exam centre; therefore candidates must download a colour
          print-out of the same. It is advised to download two copies, as one
          copy is to be submitted at the exam centre.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The CAT Admit card contains the exam center address, exam time,
          reporting time, google map link to locate the test center, and exam
          day instructions. Steps to download CAT Admit Card 2022 are:
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Visit the official CAT website- iimcat.ac.in.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Enter your CAT ID and Password to login.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Click on ‘Download CAT 2022 Admit Card’
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Your hall ticket will be displayed on the screen.{" "}
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Download and take a printout.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Save the soft copy of your admit card for future reference.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Exam Day Guidelines</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 exam will be conducted as per the government COVID-19
          guidelines. The list of documents to carry at the CAT exam centre and
          items prohibited at the CAT 2022 exam centre are listed below.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Documents to Carry</b>
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Admit Card: Candidates appearing for the CAT 2022 exam must carry two
            print outs (preferably colour print out) of their admit cards with
            their photo affixed on it. Entry without admit card and photo ID will
            not be permitted.{" "}
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Identity Proof: Candidates are required to carry a valid photo ID
            proof such as Aadhar Card, Voter ID Card, Passport, PAN Card, Driving
            License, or a Photo ID issued by the current education institute or
            employer.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Name Change Document: In case a candidate has changed their name, they
            will be required to carry a name change certificate/document to
            validate it.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Items prohibited inside the CAT 2022 Exam Hall</b>
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Calculator, watch, mobile phone, iPad or any other electronic item
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Bags and purses
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Eatables and beverages
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Jewellery and accessories that contain metal
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Jackets and clothes with pockets
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Closed footwear{" "}
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Note</b>: Candidates with a metal implant, pacemaker, etc. in their
          body will be required to bring medical certificate of the same to the
          test centre.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Answer Key 2022</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 Answer Key will be released expectedly a week after the
          conclusion of CAT exam. Along with the answer key, IIM will also
          release the response sheet for CAT 2022 exam on the official website-
          iimcat.ac.in. Using the answer key and response sheet, candidates can
          calculate their raw scores.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Also, the answer key objection window will be opened for 4-5 days
          before the declaration of CAT 2022 result.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Steps to Check CAT 2022 Answer Key</b>
        </p>
        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Visit the official IIM CAT official website- iimcat.ac.in.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Login using your CAT user id and password.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Click on 'CAT 2022 Answer Key' will appear on the screen.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Click on 'Download' icon to save it as PDF for future reference.
          </p>
        </div>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>CAT 2022 Result and Scorecard</b>
        </p>
        <p
          className="cont"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            CAT 2022 result will be released in the second week of January 2023 on
            the official website of iimcat.ac.in.{" "}
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidates will have to log in using their registration id and
            password to check the same.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            The CAT 2022 result cum scorecard contains each candidate’s raw scores
            (sectional and overall) and percentiles (section and overall).
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            CAT Result 2022 will be valid for 1 year from the date of its release.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            The scorecard will be available for download till Dec 31, 2023.
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Along with the result, IIM will also display the list of CAT Toppers
            2022.
          </p>
        </div>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT Score vs Percentile</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT Score vs Percentile analysis is useful in calculating the score
          needed to get a desired percentile. CAT Score is the total score
          obtained in the exam based on the marking scheme. Scaled CAT scores
          are different from the raw scores. Scaled scores are calculated by IIM
          after normalizing raw scores for all 3 CAT slots. CAT Percentile is
          calculated based on the scaled scores of the candidates.{" "}
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Tabulated below is the expected score vs. percentile of CAT 2022 based
          on the previous year exams score:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Percentile
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Score – VARC
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Score – DILR
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Score – QA
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Score- Overall
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  99.5+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  38 – 40
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  32 – 34
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  39 – 41
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  105 – 108
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  99+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  34 – 35
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  28 – 30
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  36 – 37
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  98 – 102
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  98+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  32 – 33
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  27 – 28
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  30 – 32
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90 – 93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  95+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  32 – 34
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  20 – 21
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  28 – 30
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80 – 82
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  29 – 30
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  17 – 19
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  26 – 27
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  72 – 75
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  24 – 25
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  14 – 15
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  20 – 22
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  59 – 61
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "1.15in",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={110}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80+
                </p>
              </td>
              <td
                style={{
                  width: "97.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  20 – 22
                </p>
              </td>
              <td
                style={{
                  width: "93.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={124}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  11 – 13
                </p>
              </td>
              <td
                style={{
                  width: "86.55pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={115}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  15 – 16
                </p>
              </td>
              <td
                style={{
                  width: "100.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={134}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  46 – 48
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>CAT 2022 Result &amp; IIMs’ Final Selection Process</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT 2022 exam official result declaration date is expected in second
          week of January 2023; however, IIMs may release it in the first week
          of January 2023. IIMs will start announcing their shortlist candidates
          on the basis of CAT scores and other parameters in their shortlisting
          criteria for final selection round.{" "}
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The admission process in IIMs depends upon performance in three major
          areas:
        </p>
        <p
          className="cont1"
          style={{
            marginLeft: "38.25pt",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          1.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          CAT 2022 exam score
        </p>
        <p
          className="cont1"
          style={{
            marginLeft: "38.25pt",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          2.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Academic record{" "}
        </p>
        <p
          className="cont1"
          style={{
            marginLeft: "38.25pt",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          3.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Performance in Personal Interview rounds (GD-WAT-PI)
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The final admission offer will be made to the candidates in the months
          of April/May 2023 by IIMs&nbsp;{" "}
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The final admission offer depends upon performance in all the above
          three stages of IIMs selection process. While the older IIMs conduct
          their individual selection process, 9 new and baby IIMs conduct common
          admission process (CAP) for admission. Remaining IIMs conduct their
          individual selection process.&nbsp;{" "}
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top IIMs Conduct Individual Admission Process</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIM Ahmedabad, IIM Bangalore, IIM Calcutta, IIM Lucknow, IIM Indore,
          IIM Kozhikode and IIM Shillong individually shortlist the candidates
          and conduct their own admission process by awarding weightage to
          academics, diversity, work experience apart from CAT exam score
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Common Admission Process (CAP) for 9 IIMs</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The final selection process at 9 new IIMs is conducted as a common
          process. One of the six new IIMs co-ordinates this selection process
          which is referred to as Common Admission Process (CAP). The CAP 2023
          will consist of common WAT and PI round for 9 new IIMs namely IIM
          Kashipur, IIM Raipur, IIM Ranchi,&nbsp; IIM Trichy, IIM Udaipur and
          some of the newest IIMs like IIM Bodhgaya, IIM Sambalpur, IIM Sirmaura
          and IIM Jammu.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            IIM Nagpur, IIM Rohtak, IIM Visakhapatnam to conduct individual
            admission process
          </b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          IIM Nagpur, IIM Rohtak and IIM Visakhapatnam do not participate in CAP
          round and conduct their own final selection process to make admission
          offer to the students. The final admission offers is generated as per
          the merit lists prepared on the basis of CAT scores, Academic profile,
          Work experience and gender diversity and weightage given to each
          category.&nbsp;{" "}
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>IIM CAT Cut Off Percentiles</b>
        </p>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          CAT cut off for each IIM admission is fixed basis their admission
          process. While the qualifying cut offs range between 80 and 90
          percentile (General category), the final cut offs are much higher.
          Given below is the table indicating qualifying CAT cut offs:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    IIM
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Cut-off (Qualifying)
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Ahmedabad
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Bangalore
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Calcutta
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Lucknow
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Kozhikode
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Indore
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Rohtak
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  95
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Visakhapatnam
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Trichy
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Udaipur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Ranchi
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Amritsar
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Shillong
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Kashipur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Sambalpur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Jammu
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Srimaur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Nagpur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Raipur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IIM Bodhgaya
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont mb-0"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Note: The cut off percentiles mentioned in the above table are of
          General category.
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Admission to Top Non-IIMs through CAT scores</b>
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Getting into IIMs is tough as the cut offs and competition is very
          high. However, there are 2000+ colleges other than IIMs that accept
          CAT scores for MBA / PGDM admissions. Top Non-IIM MBA institutes
          include FMS Delhi, Delhi School of Economics, IITs, KJ Somaiya, etc.
          CAT 2022 admission process for Non-IIMs will include the
          below-mentioned stages.{" "}
        </p>

        <div className="required-skills">
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidate need fill the application form of the target non-IIM college
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidates need to clear the qualifying CAT 2022 cut off to be
            eligible
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Qualified candidates will be shortlisted for participation in WAT/ GD/
            PI
          </p>
          <p
            className="cont"
            style={{
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Candidates will be selected on the basis of CAT scores, GD/ PI scores,
            and academic qualifications depending on the parameters set by the
            respective institute
          </p>
        </div>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Top 20 non-IIMs accepting CAT scores for MBA / PGDM admission is
          provided in the table below along with the expected cut off percentile
          based on the analysis of previous years’ cut off data:
        </p>

        <table
          className="MsoTableGrid"
          style={{
            borderCollapse: "collapse",
            border: "none",
          }}
          width={638}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Non IIM Institute
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    CAT Percentile Cut Off (Qualifying)
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  FMS, Delhi
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  98 – 99
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Goa Institute of Management (GIM), Goa
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  90 – 95
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IBS, Hyderabad
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  85 – 90
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Institute of Management Technology, Ghaziabad
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  94 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jamnalal Bajaj Institute of Management Studies (JBIMS),
                  Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  90 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  K. J. Somaiya Institute of Management Studies and Research
                  (SIMSR), Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  90 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Management Development Institute (MDI), Gurgaon
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  97 – 98
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Prin. L. N. Welingkar Institute of Management Development
                  &amp; Research (WeSchool), Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  85 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  S.P. Jain Institute of Management and Research (SPJIMR),
                  Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  85 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Shailesh J. Mehta School of Management (SJMSOM), Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  95 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  T. A. Pai Management Institute (TAPMI), Manipal
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  80+
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  IMI, Delhi
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  90+
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  FORE, Delhi
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  85 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Great Lakes Institute of Management, Chennai
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  85 +
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "362.05pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={483}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  BIMTECH, Greater Noida
                </p>
              </td>
              <td
                style={{
                  width: "116.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={156}
              >
                <p
                  className="cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  75 +
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <CustomTable />

        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="cont"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>FAQs CAT Exam 2022</b>
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the CAT 2022 exam date?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: CAT 2022 exam will be held on November 27, 2022.&nbsp; The exam
          will be held in three time slots:
        </p>
        <div className="required-skills">
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Morning, Slot 1: 8:30 AM to 10:30 AM
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Afternoon, Slot 2: 12:30 PM to 2:30 PM{" "}
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Evening, Slot 3: 4:30 PM to 6:30 PM
          </p>
        </div>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: When will CAT 2022 registrations begin?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: CAT 2022 registrations began from August 3, 2022, 10:00 AM. The
          application forms will be available on the official website{" "}
          <a href="https://iimcat.ac.in">https://iimcat.ac.in</a>
          of CAT till September 14. Candidates will have to register online,
          fill the application form and pay the requisite fees to be eligible to
          appear for the exam.{" "}
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the difficulty level of CAT exam?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The difficulty level of any examination depends upon the
          preparation of the candidate. CAT 2021 exam was of moderate difficulty
          level. All the three slots had same difficulty level. With the right
          preparation strategy and a lot of practice, candidates can easily
          crack the entrance test with good percentile.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are all the sections available in the CAT 2022 syllabus?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: There are three sections available in the CAT exam syllabus,
          namely:
        </p>
        <div className="required-skills">
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Verbal Ability and Reading Comprehension (VARC)
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Data Interpretation &amp; Logical Reasoning (DILR)
          </p>
          <p
            className="cont"
            style={{
              marginLeft: "0.5in",
              textJustify: "inter-ideograph",
              textIndent: "-.25in",
            }}
          >
            3.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            Quantitative Ability (QA)
          </p>
        </div>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What are the benefits of appearing for CAT?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The foremost benefit of appearing for CAT is that through the
          score of the exam students can get admission in top B-school of India
          including the IIMs and IITs for management program. The entrance test
          also helps the students in improving their logical thinking, reasoning
          and time management skills.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. How many B-schools accept CAT score?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: CAT is the most important national level management course
          entrance exam. In India there are around 4000 B-schools. All of them
          including the 20 IIMs accept the CAT score. Aspirants are advised to
          visit the official website of the selected college to find out if the
          college accept CAT score or not and what is the cut-off for the
          particular college.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top MBA colleges I can apply to with 80 percentile
          CAT score?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Fore School of Management - Delhi, Goa Institute of Management -
          Goa, International Management Institute - Delhi, Indian Institute of
          Forest Management - Bhopal, Loyola Institute of Business
          Administration - Chennai, Lal Bahadur Shastri Institute of Management
          - Delhi, Pune Institute of Business Management - Pune, Prin L N
          Welingkar Institute of Management development &amp; Research, Pune and
          TA Pai Management Institute - Bangalore are some of the top colleges
          you can apply to with a CAT score of 80 percentile. &nbsp;
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the application fee for CAT exam?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: CAT 2022 exam application fee Rs. 2,300 for General Category and
          Rs. 1,150 for Reserved Category students. The fee is to be paid in
          online mode only via debit / credit card or net banking.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Is there an age limit to appear in CAT exam?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: No, there is no age limit to appear in the CAT exam. Any
          candidate who is a graduate or is in the final year of graduation is
          eligible to apply for CAT. Working professionals are also eligible to
          appear in CAT.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. I am in the second year of graduation; can I appear for CAT for
          practice purpose?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: It is not advisable to appear for CAT with fulfilling the minimum
          eligibility criteria. If you haven't completed graduation and/or are
          not in the final year of bachelor's degree, you are not eligible.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. How is the CAT exam center allotted?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The examination conducting body allots the test centre on the
          basis of availability and preference made by the candidates on their
          application form. Although not guaranteed, candidates usually get the
          first or second choice CAT exam centre of their choice.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Can I select my preference for shift and test date?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The CAT exam is conducted on one day in three shifts, so there is
          no choice of dates. The exam slot is allotted by the exam authority as
          per availability of seats at a particular exam centre. A candidate
          cannot choose exam shift / slot of their choice.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Can I appear for more than one slot of CAT exam?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: No, a candidate can appear for only one slot in the CAT exam.
          Even the test slot is allotted to the candidate by the exam authority
          and is not of candidates choosing. To ensure every candidate attempts
          the exam in only the slot allotted - admit cards, photo IDs, candidate
          photo and biometric records are verified at the exam centre.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Are there any special provisions for PwD candidates?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, there are special provisions for Persons with Disability
          (PwD) candidates, such as scribe assistance and extra exam hours. The
          PwD candidates get 20 minutes extra per CAT exam section. To avail
          these facilities, the candidates have to upload PwD and scribe
          certificates along with the CAT application form and carry the same to
          the exam centre.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Am a EWS category student, which documents do I need to upload with
          CAT application form?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: A EWS category student needs to upload the EWS category form
          available on the CAT website along with the CAT application form. The
          form should be duly filled and self-attested before submission.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the marking scheme for CAT exam? Is there negative marking?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: In the CAT exam MCQ carry 3 marks for every correct response with
          negative marking of 1/3 (-1) for every wrong MCQ response. No negative
          marking for wrong non-MCQ response. There is no negative marking for
          questions not attempted, so the candidates must choose their options
          wisely.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. How do we calculate the CAT percentile?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Percentile in any entrance exam is just a representation of the
          number of people who are behind you in the exam. The general formula
          to calculate percentile along with an examples is detailed out below:
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Let us assume that 50 candidates appeared for the CAT exam. A
          candidate has scored 5<sup>th</sup> rank in the exam, which means 45
          students scored less than you and are below you in rank. So the
          percentile would be calculated as: 45 / 50 x 100 = 90 Percentile.
        </p>

        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the scholarship schemes in IIMs that candidates appearing
          in CAT can avail of upon admission?
        </p>
        <p
          className="cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: IIMs provide scholarships to meritorious candidates and the ones
          belonging to the economically weaker section. The candidates can avail
          this benefit by submitting the relevant documents at the time of
          admission. For more details about scholarship student should visit the
          official website of respective IIMs.
        </p>
      </div>
    </div>
  );
};

export default CAT2022;
