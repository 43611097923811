import CustomTable from "../../../CustomTable";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const FinanceManagemant = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <link rel="stylesheet" href="../custom.css" />
      {/* <div>
        <img src="img/banner.jpg"/>
      </div> */}
      <div className="WordSection1">
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Finance</b>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA / PGDM in Finance is one of the most preferred specialization
          which provides skills in Financial Management, Corporate Financial
          Planning, Corporate Acquisitions &amp; Mergers, Accounting, and
          Banking. MBA / PGDM Finance syllabus is designed in such a way that it
          enables the students to analyse the Financial Reports, Forecast
          financial and economic trends, making stock profitable, making a
          balance approach between risk and profitability, managing and
          maximizing investment portfolio with minimum risk factor.
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA in Finance degree equips an individual with the ability, knowledge
          and techniques to get employment in corporate as well as government
          sector. MBA in Finance graduates can also get jobs in stock markets,
          audit agencies and taxation firms. They can also set up their own
          business.
        </p>
        <b className="table-details">Table of content</b>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#1">
            MBA in Finance – Program Highlights
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#2">
            MBA in Finance – Syllabus
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#3">
            MBA in Finance: Skills Required
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#4">
            MBA in Finance: Job Profiles Offered
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Top Colleges in India for MBA in Finance
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#6">
            MBA in Finance - Top Recruiters
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#7">
            FAQs – MBA In Finance
          </a>
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className=" .MsoListParagraphCxSpMiddle"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <p className="headings" id="1">
            MBA in Finance – Program Highlights
          </p>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Parameters
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Program Type
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation Degree with minimum 50 per cent in any discipline
                  (45 per cent for reserved category)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Test followed by GD – WAT – PI
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT, XAT, CMAT, MAT, NMAT, etc
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Program Options
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full Time, Part Time, Distance, Online
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Portfolio management
                </p>
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Small business and corporate finance
                </p>
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Investments
                </p>
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Securities analysis
                </p>
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Borrowing
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Fees
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 50,000 to Rs. 25 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 3 Lac to 15 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Job Profiles
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Financial Advisor
                </p>
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Budget Analyst
                </p>
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Financial Planner
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <p className="headings" id="2">
            MBA in Finance – Syllabus
          </p>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from the core subjects, candidates are taught the following
          subjects in MBA in Finance specialisation programme:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Financial Risk Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bank Management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Structured Finance
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Options, Futures &amp; Derivatives
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Investment Analysis and Portfolio Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Behavioural Finance
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Total Quality Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Strategic Management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Financial Modelling
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Commodity Finance
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Corporate investment management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className=" cont"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hedge fund management
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            <p className="headings" id="3">
              MBA in Finance: Skills Required
            </p>
          </p>

          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            Functional-specific skills that one need to have to succeed in MBA /
            PGDM in Finance are:
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Strong quantitative skills
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Financial skills
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Business and management interest
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Critical thinking
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Analytical skills
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Numerical ability
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Critical thinking and pulse on business
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            8.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Data interpretation and analysis skills
          </p>

        </div>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>




        <div className="required-skills">
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            <p className="headings" id="4">
              MBA in Finance: Job Profiles Offered
            </p>
          </p>
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            An MBA / PGDM in Finance can work in banking as well as non-banking
            sectors. Every business organisation need finance professionals and
            hiring is done at junior, middle and senior level to take care of the
            finance and financial assets of the company. Here is the list of few
            popular job profiles in this domain:
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Corporate Finance
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Corporate Banking
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Credit Risk Management
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Asset Management
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Hedge Fund Management
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Private Equity
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Treasury
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Derivatives Structuring
          </p>


        </div>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <p className="headings" id="5">
            Top Colleges in India for MBA in Finance
          </p>
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Several Indian management institutes provide MBA / PGDM programmes in
          finance. Admission is based on prior academic performance and entrance
          test scores. To find out about the top colleges of India offering MBA
          / PGDM in Finance,{" "}
          <span style={{ color: "red" }}>
            <Link to="/college-list?m=Top Private MBA Colleges">
              click here
            </Link>
          </span>
          .
        </p>

        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            <p className="headings" id="6">
              MBA in Finance - Top Recruiters{" "}
            </p>
          </p>
          <p
            className=" cont"
            style={{ textAlign: "justify", textJustify: "inter-ideograph", marginLeft: "0" }}
          >
            After completing your MBA / PGDM in Finance, career opportunities are
            open in banking, finance, accounting, taxation, asset management etc
            areas that offer high salary packages to the finance management
            graduates. Here is the list of some of the top recruiting companies in
            B-schools hiring for MBA / PGDM in Finance candidates:
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Morgan Stanley
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Merrill Lynch
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            JP Morgan
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Goldman Sachs
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Boston Consulting Group
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            McKinsey
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Deloitte
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Citi Bank
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            KPMG
          </p>
          <p
            className=" cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            ICICI Bank
          </p>
        </div>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <CustomTable />
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <p className="headings" id="7">
            FAQs – MBA in Finance
          </p>
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the career scope after MBA / PGDM in Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Every business organisation need finance professionals and hiring
          is done at junior, middle and senior level to take care of the finance
          and financial assets of the company. MBA / PGDM in Finance will open
          the door for jobs at executive as well as managerial positions in good
          companies. After completion a candidate can get a job as a Financial
          Advisor, Financial Planner, Budget Analyst etc.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. &nbsp;Who is eligible to pursue MBA / PGDM in Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Anyone with a legitimate graduation degree in any discipline with
          minimum 50 per cent marks is eiligible to pursue MBA / PGDM in
          Finance. For reserved category students, the minimum mark is 45 per
          cent.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Is Maths necessary for MBA in Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Maths is an integral part of all MBA / PGDM specialisation. For
          MBA / PGDM in Finance, good command on Maths is an advantage as the
          course includes some topics of Accounting, Taxation, Economics and
          Finance.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Is MBA / PGDM in Finance difficult as compared to other
          specializations?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: It totally depends upon the student perspective. Some may find HR
          or Marketing easier and opt for the same, while for others MBA / PGDM
          in Finance is a better and easier choice to study.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the course fee of MBA / PGDM in Sales and Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: MBA / PGDM in Finance is offered as a specialization in
          B-schools. The course fee for entire 2 years ranges from Rs. 50,000 to
          Rs. 25 Lac.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the average salary of a fresher after completing MBA / PGDM
          in Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The average salary of MBA / PGDM in Finance ranges between Rs. 5
          Lac to Rs. 25 Lac per annum.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the admission process for MBA / PGDM in Finance course?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Admission process is mostly dependent on the B-school a student
          is targeting. In general the admission processed comprises of entrance
          test followed up by group discussion and personal interview.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: MBA in Finance or MBA in Marketing, which specialisation has more
          scope?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Both marketing and finance are very popular and have a lot of
          scope, as they are two of the most important department in any
          industry, sector or organization. The candidate must choose either of
          the two specializations or any other specializations of their choice
          based on their skills, interest and future goal.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top colleges for MBA in Finance in India?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Top colleges in India for MBA / PGDM in Finance include S P Jain
          Institute of Management and Research (SPJIMR), Mumbai, Institute of
          Management Technology (IMT), Ghaziabad, Jamnalal Bajaj Institute of
          Management Studies (JBIMS), Mumbai, K J Somaiya Institute of
          Management Studies &amp; Research, Mumbai,{" "}
          <span style={{ color: "red" }}>
            (more names of our&nbsp; tie up colleges to be written)
          </span>
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What do you study in MBA / PGDM in Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Apart from the core subjects students in Finance, a student has
          to study specialisation centric subjects like Money and Banking,
          Financial Risk Management, Management Control System, International
          Finance, Bank Management, Fixed Income Markets, Structured Finance,
          Infrastructure and Project Finance, Options, Futures &amp;
          Derivatives, Business Valuation, Investment Analysis and Portfolio
          Management, Corporate Restructuring, Behavioural Finance, Trading
          Strategies etc.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top companies for recruitment for MBA / PGDM in
          Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Hiring is done at all levels for a finance graduate. Some of the
          top recruiters for MBA / PGDM in Finance include Morgan Stanley,
          Deutsche Bank, Deloitte, E &amp; Y, KPMG, McKinsey, Barclays, Boston
          Consulting Group, Goldman Sachs etc.
        </p>
        <p
          className=" cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top entrance exams for MBA / PGDM in Finance?
        </p>
        <p
          className=" cont"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The top entrance exams for MBA in Finance are CAT, XAT, SNAP,
          NMAT, MAT, CMAT, and GMAT.
        </p>
      </div>
    </div>
  );
};

export default FinanceManagemant;
