import { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../CustomTable";
const Energy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (

    <div>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <link rel="stylesheet" href="../custom.css" />
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Energy Management</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA in Energy Management teaches a student about changing realities in
          the energy sector in the country. &nbsp;The level of development or
          advancements in a country depends on the energy consumption of that
          country. Energy, however, is something which is not free and humans
          are paying a severe price for it in the form of environment
          degradation.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Due to massive environment degradation and shortage of skilled people
          to tackle environment related problems, there is a good scope for
          candidates in the industry.&nbsp; It mainly focuses on developing
          leaders who can create an impact and meet the challenges of the energy
          sector. It gives exposure to best practices and innovations in this
          sector and makes an individual facilitator for responsiveness,
          understanding, and execution of enactments related to energy.
        </p>

        <b className="table-details">Table of content</b>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#1">
            MBA In Energy Management  – Program Highlights</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#2">
            MBA In Energy Management   – Syllabus</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#3">
            MBA In Energy Management  : Skills Required</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#4">
            MBA In Energy Management : Job Profiles Offered</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >

          Top Colleges in India for MBA In Energy Management
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#6">
            MBA In Energy Management  - Top Recruiters
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#7">
            FAQs – MBA In Energy Management
          </a>
        </p>


        <p id="1"
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Energy Management – Program Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Parameters
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Program Type
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation Degree with minimum 50 per cent in any discipline
                  (45 per cent for reserved category)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Test followed by GD – WAT – PI
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT, XAT, CMAT, MAT, NMAT, etc
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program Option
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full Time, Distance, Online
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Energy Audits and Energy Management
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Oil and Gas Economics
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Global Business Environment
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Non-Renewable Energy Sources
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Renewable Energy Sources
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Fees
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 1 Lac to Rs. 15 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 5 Lac to 10 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Job Profiles
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Energy Markets Reporter
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Research Associate
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Energy Auditor
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Energy Consultant
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />

        <p
          className="MsoNormal" id="2"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>


        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Energy Management – Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from the core subjects, candidates are taught the following
          subjects in MBA in Enerygy Management specialisation programme:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Business Statistics
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Managerial Economics
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Advance Data Driven Decision Making
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Non Renewable Energy Sources
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Renewable Energy Sources and Technologies
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Climate Change, Carbon Markets and Financing
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Environment Impact Assessment
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Governance and Corporate Sustainability
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Energy Audits and Energy Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Oil and Gas Economies
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Environment Management Systems
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Global Business Environment
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal" id="3"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Energy Management: Skills Required</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Functional-specific skills that one need to have to succeed in MBA /
            PGDM in Energy Management are:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Management skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Technical skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Research skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Problem-solving skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Commitment
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Leadership skills
          </p>

        </div>
        <p
          className="MsoNormal" id="4"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Energy Management: Job Profiles Offered</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            MBA Energy Management prepares the students to showcase their
            leadership and innovative skills in the growing sectors of energy
            management. Candidates who have successfully completed their courses
            with high ranks have wide career prospects to choose from in various
            private and public sector firms. Given below are some popular job
            profiles for energy graduate students:
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Environmental Specialist
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Energy Manager
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Research Associate
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Safety Officer
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Strategy Consultant
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Energy Management Specialist
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Project Engineer
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Energy Auditor
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Field Engineer
          </p>
        </div>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top Colleges in India for MBA in Energy Management</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Only few colleges in India offer Energy program. Some of the top
          colleges are NIT, Kurukshetra, Amity University, Noida, Ahmedabad
          University, National Power Training Institute, Faridabad, University
          Of Petroleum And Energy Studies (UPES), Dehradun. To find out about
          the top colleges of India offering MBA / PGDM in Rural Management,{" "}
          <span style={{ color: "red" }}><Link to="/college-list?m=Top Private MBA Colleges">
            click here
          </Link>
          </span>.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Energy Management - Top Recruiters </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            After completing MBA / PGDM in Energy Management, candidates have
            opportunity to work with some of the top companies such as
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            &nbsp;
          </p>

          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Adani Power
          </p>
          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>NHPC
          </p>
          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>NTPC
          </p>
          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Tata Power
          </p>
          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Damodar Valley Corporation
          </p>
          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Reliance Power
          </p>
          <p
            className="cont"
            style={{
              // marginLeft: "1in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>GAIL
          </p>

        </div>
        
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        />
        <CustomTable />
        <p
          className="MsoNormal" id="7"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>FAQs – MBA in Energy Management</b>
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the scope of MBA in Energy Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Energy graduate candidates are in demand due to shortage of
          skilled people to tackle environment related problems. MBA / PGDM in
          Energy Management develop leaders who can create an impact and meet
          the challenges and demands of the energy sector.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the difference between MBA Engineering Management and MBA
          energy Management and which one is better among them?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: MBA in Engineering Management focuses on core concepts related to
          business and management, students also examine manufacturing
          strategies, supply chain management and engineering technology. MAB in
          Energy Management teaches a student about changing realities, demands
          and hurdles in the energy sector of the country.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the role of a Certified Energy Manager?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: A Certified Energy Manager optimizes the energy performance of a
          facility, building, or industrial plant. He / she is a systems
          integrator for electrical, mechanical, process, and building
          infrastructure, analyzing the optimum solutions to reduce energy
          consumption in a cost-effective approach.
        </p>
        
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. For MBA in Energy Management Distance Education?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, MBA in Energy Management can be pursued by distance
          education mode. Students can also opt for Online mode.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q.&nbsp; Who is eligible to pursue MBA in Energy Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: MBA in Energy Management is reserved for students with B. Tech or
          B Sc background. Candidates with work experience of 2 – 3 years in
          similar field are also eligible. Apart from this student should have a
          legitimate graduation degree with minimum 50 per cent mark. For
          reserved category students, the minimum mark is 45 per cent.
        </p>
        
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top colleges for MBA in Energy Management in India?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Top colleges in India for MBA in Energy Management are NIT,
          Kurukshetra, Amity University, Noida, Ahmedabad University, National
          Power Training Institute, Faridabad, University Of Petroleum And
          Energy Studies (UPES), Dehradun. To find out about the top colleges of
          India offering MBA / PGDM in Rural Management,{" "}
          <span style={{ color: "red" }}><Link to="/college-list?m=Top Private MBA Colleges">
            click here
          </Link>
          </span>.
        </p>
        
        <p
         className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top entrance exams for MBA in Energy Management?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The top entrance exams for MBA in Energy Management are CAT, XAT,
          MAT, CMAT, and GMAT.
        </p>
        
      </div>
    </div>
  );
};
export default Energy;
