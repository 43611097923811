import { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../CustomTable";
const ImportExport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <link rel="stylesheet" href="../custom.css" />
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Import and Export</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          MBA in Import &amp; Export give foundation to a career in
          international markets and foreign trade. The program teaches
          candidates regarding product development, payments, financial
          processes, documentations, international business etc. With the
          opening up of international market, opportunities for qualified and
          skilled professionals to administer the import &amp; export process
          have grown enormously.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Candidates after pursuing the course are inducted in global firms in
          domains like pricing, sourcing, costing, export market research,
          distribution, freight marketing, export finance, documentation and
          foreign exchange. Candidates find opportunities in companies like
          Reliance Industries, Indian Oil, Bharat Petroleum, Tata Motors etc as
          Export Manager, Import Manager, International Logistics Supply
          Manager.
        </p>

        <b className="table-details">Table of content</b>

        <p
          className=" MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#1">
            MBA In Import and Export Management  – Program Highlights</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#2">
            MBA In Import and Export Management   – Syllabus</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#3">
            MBA In Marketing  : Skills Required</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#4">
            MBA In Import and Export Management : Job Profiles Offered</a>
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >

          Top Colleges in India for MBA In Import and Export Management
        </p>
        <p
          className=" MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#6">
            MBA In Import and Export Management  - Top Recruiters
          </a>
        </p>
        <p
          className=" MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <a className="link" href="#7">
            FAQs – MBA In Import and Export Management
          </a>
        </p>


        <p
          className="MsoNormal" id="1"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Import and Export – Program Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Parameters
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    Details
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Program Type
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  2 years Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Eligibility
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Graduation Degree with minimum 50 per cent in any discipline
                  (45 per cent for reserved category)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    fontWeight: "bold",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission Process
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Test followed by GD – WAT – PI
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    fontWeight: "bold",
                    lineHeight: "normal",
                  }}
                >
                  Entrance Exams
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  CAT, XAT, CMAT, MAT, NMAT, etc
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                  fontWeight: "bold",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Program Options
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Full Time, Certificate, Distance
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Logistics
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Marketing
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Financial Management
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Export and Import Procedure
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  India's Export-Import Trade since 1955
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Fees
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 2 Lac 000 to Rs. 20 Lac
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    fontWeight: "bold",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Average Salary
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 4 Lac to 10 Lac per annum
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "99pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={132}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                    fontWeight: "bold",
                  }}
                >
                  Job Profiles
                </p>
              </td>
              <td
                style={{
                  width: "379.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={506}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Export Manager
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Import Manager
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  International Supplies Logistic Manager
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <CustomTable />
        <p
          className="MsoNormal" id="2"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>MBA in Import and Export – Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Apart from the core subjects, candidates are taught the following
          subjects in MBA / PGDM in Import and Export specialisation programme:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Managerial Economics
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Foreign Language
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Logistics
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Marketing
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative methods
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Financial Management
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  International Economics
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Export and Import Procedure
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Foreign Exchange Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  India's Export-Import Trade since 1955
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Commercial Geography
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Project report and presentation
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          className="MsoNormal" id="3"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Import and Export: Skills Required</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            To pursue MBA in Import &amp; Export course, candidates must have the
            following skill sets:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Leadership qualities
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Knowledge about the international business
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Logical thinking
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Analytical Ability
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Communication Skills
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
            Management Skills
          </p>
        </div>
        <p
          className="MsoNormal" id="4"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>

        <div className="required-skills">

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Import and Export: Job Profiles Offered</b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            Manufacturing companies, banks, financial institutions and government
            agencies that trade outside their countries lucratively hire such
            professionals. Such postgraduates are entrusted with tasks like
            analyzing technical data in international business deals and in
            spheres of international trade policies &amp; agreements. Successful
            postgraduates of the course are hired in capacities such as:
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Foreign Trade Manager
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Export Manager
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Relationship Manager
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Foreign Trade Analyst
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Foreign Trade and Purchasing Specialist
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Supply Chain Manager
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Market Research Executive
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Global Trade Manager
          </p>
        </div>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Top Colleges in India for MBA in Import and Export</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Some of the top colleges offering MBA / PGDM in Import &amp; Export or
          related courses are Indian Institute of Foreign Trade (IIFT), New
          Delhi, KJ Somaiya Institute Of Management Studies And Research,
          Mumbai, Symbiosis Institute of Management Studies, Pune, Fore School
          of Management, New Delhi, Fortune Institute of International Business,
          New Delhi, Symbiosis Institute of International Business, Pune, To
          find out more about the top colleges of India offering MBA / PGDM in
          Import &amp; Export, <span style={{ color: "red" }}>
            <Link to="/college-list?m=Top Private MBA Colleges">
              click here
            </Link></span>.
        </p>

        <div className="required-skills">
          <p
            className="MsoNormal" id="6"
            style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
          >
            <b>MBA in Import &amp; Export - Top Recruiters </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            All companies doing trade internationally look to hire graduates in
            MBA / PGDM in Import &amp; Export. Candidates can find opportunities
            in private companies as well as PSUs. Some of the top import &amp;
            export companies that provide opportunities to candidates in various
            profiles and domains:
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              textJustify: "inter-ideograph",
            }}
          >
            &nbsp;
          </p>

          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Reliance Industries
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Indian Oil
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Coal India
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Tata Motors
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Tata Steel
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Bharat Petroleum
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Mahindra &amp; Mahindra
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>ITC
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Hindalco
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Mahindra &amp; Mahindra
          </p>
          <p
            className="cont"
            style={{
              textAlign: "justify",
              textJustify: "inter-ideograph",
              textIndent: "-0.25in",
            }}
          >
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>Sun Pharma
          </p>
        </div>
       
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <CustomTable />
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal" id="7"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>FAQs – MBA in Import &amp; Export</b>
        </p>
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the career scope after MBA / PGDM in Import &amp; Export?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Candidates after pursuing this course are inducted in global
          firms in domains like pricing, sourcing, costing, export market
          research, distribution, freight marketing, export finance,
          documentation and foreign exchange. Candidates find opportunities in
          companies like Reliance Industries, Indian Oil, Bharat Petroleum, Tata
          Motors etc as Export Manager, Import Manager, and International
          Logistics Supply Manager, etc.
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. &nbsp;Who is eligible to pursue MBA / PGDM in Import &amp; Export?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Anyone with a legitimate graduation degree in any discipline with
          minimum 50 per cent marks is eiligible to pursue MBA / PGDM in Import
          &amp; Export. For reserved category students, the minimum mark is 45
          per cent.
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the course fee of MBA / PGDM in Import &amp; Export?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: MBA / PGDM in Import &amp; Export is offered as a specialization
          in few B-schools. The course fee for entire 2 years ranges from Rs. 2
          Lac to Rs. 20 Lac.
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. What is the average salary of a fresher after completing MBA / PGDM
          in Import &amp; Export?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The average salary of a fresher after graduating from MBA / PGDM
          in Import &amp; Export ranges between Rs. 4 – 5 Lac.
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: MBA in International Business or MBA in Import &amp; Export, which
          specialisation has more scope?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Both International Business and Import &amp; Export are very
          popular and have a lot of scope and both the specialization has some
          similarities also. The candidate must choose either of the two
          specializations or any other specializations of their choice based on
          their skills, interest and future goal.
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top colleges for MBA in Import &amp; Export in India?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Top colleges offering MBA / PGDM in Import &amp; Export or
          related courses are Indian Institute of Foreign Trade (IIFT), New
          Delhi, KJ Somaiya Institute Of Management Studies And Research,
          Mumbai, Symbiosis Institute of Management Studies, Pune, Fore School
          of Management, New Delhi, Fortune Institute of International Business,
          New Delhi, Symbiosis Institute of International Business, Pune, To
          find out more about the top colleges of India offering MBA / PGDM in
          Import &amp; Export, <span style={{ color: "red" }}>
            <Link to="/college-list?m=Top Private MBA Colleges">
              click here
            </Link></span>.{" "}

        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top companies for recruitment for MBA / PGDM in
          Import &amp; Export?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Hiring is done mostly at starting level provided a candidate has
          prior work experience of similar field. Some of the top import &amp;
          export companies that provide opportunities to candidates in various
          profiles and domains are Reliance Industries, Indian Oil, Coal India,
          Tata Motors, Tata Steel, ITC, Hindalco, Sun Pharma, etc.
        </p>
       
        <p
          className="cont headings"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Which are the top entrance exams for MBA / PGDM in Import &amp;
          Export?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The top entrance exams for MBA / PGDM in Import &amp; Export are
          CAT, XAT, IIFT, SNAP, NMAT, MAT, CMAT, and GMAT.
        </p>
      </div>
    </div>
  );
};
export default ImportExport;
